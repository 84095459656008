import { ThgVehicleStateEnum } from "@/lib/enum/thg-vehicle-state.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { ThgThgVehicleViewModelGen } from "@/services/thg/v1/data-contracts";
import { ThgVehicleModule } from "@/store/modules/thg-vehicle.store";
import {
  IIsAutoRenewalHistoryElementBase,
  IsAutoRenewalHistoryElement
} from "./thg-vehicle-autorenewal-history.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";

@IsFilterable
class ThgVehicleBase implements ThgThgVehicleViewModelGen {
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "_id"
  })
  get _id() {
    return this.id;
  }

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.thgVehicle.id"
  })
  id: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.thgVehicle.userId"
  })
  userId: string;

  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.thgVehicle.isAutoRenewal"
  })
  isAutoRenewal: boolean;

  autoRenewalHistory?: IIsAutoRenewalHistoryElementBase[] | undefined;

  @FilterConfig({
    type: FilterTypes.DATE,
    displayName: "objects.thgVehicle.decommissioningDate"
  })
  decommissioningDate?: string | undefined;

  @FilterConfig({
    displayName: "objects.thgVehicle.vehicleState",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(ThgVehicleStateEnum).map(c => ({
        text: `enums.ThgVehicleStateEnum.${c}`,
        value: c
      })),
      itemValue: "value"
    }
  })
  vehicleState: ThgVehicleStateEnum;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.thgVehicle.identificationnumber"
  })
  identificationnumber: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  isLoading = false;

  constructor(data?: Partial<ThgVehicleBase | ThgThgVehicleViewModelGen>) {
    this.id = data?.id || "";
    this.userId = data?.userId || "";
    this.isAutoRenewal = data?.isAutoRenewal || false;
    this.autoRenewalHistory = (data?.autoRenewalHistory || []).map(item => new IsAutoRenewalHistoryElement(item));
    this.decommissioningDate = data?.decommissioningDate || undefined;
    this.vehicleState = (data?.vehicleState as ThgVehicleStateEnum) || ThgVehicleStateEnum.CREATED;
    this.identificationnumber = data?.identificationnumber || "";
    this.timestamp = new Timestamp(data?.timestamp);
  }

  map(data?: Partial<ThgVehicleBase | ThgThgVehicleViewModelGen>) {
    if (data) {
      this.id = data.id ?? "";
      this.userId = data.userId ?? "";
      this.isAutoRenewal = data.isAutoRenewal ?? false;
      this.autoRenewalHistory = (data.autoRenewalHistory ?? []).map(item => new IsAutoRenewalHistoryElement(item));
      this.decommissioningDate = data.decommissioningDate ?? undefined;
      this.vehicleState = (data.vehicleState as ThgVehicleStateEnum) ?? ThgVehicleStateEnum.CREATED;
      this.identificationnumber = data.identificationnumber ?? "";
      this.timestamp = new Timestamp(data.timestamp);
    }
  }

  get decommissioningDateReadable() {
    if (!this.decommissioningDate) {
      return "";
    }
    return simpleDoubleDigitDate(this.decommissioningDate);
  }

  /**
   *
   */
  async fetch() {
    try {
      this.isLoading = true;
      this.map(await ThgVehicleModule.getOne(this.id));
    } catch (error) {
      this.isLoading = false;

      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  /**
   *
   */
  async update() {
    this.map(
      await ThgVehicleModule.updateOne({
        id: this.id,
        dto: {
          isAutoRenewal: this.isAutoRenewal,
          decommissioningDate: this.decommissioningDate?.toString() || "",
          vehicleState: this.vehicleState
        }
      })
    );
  }
}

type IThgVehicle = ThgVehicleBase;
const ThgVehicle = Filter.createForClass(ThgVehicleBase);

export { IThgVehicle, ThgVehicle };
