import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ThgThgViewModelGen } from "@/services/thg/v1/data-contracts";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";
import { UnitEnum } from "../enum/unit.enum";
import { $t } from "../utility/t";

export class ThgPriceHelper {
  constructor(private thg: ThgThgViewModelGen) {}

  /**
   * Map, die angibt, wie viele kWh pro Fahrzeugklasse und Land veranschlagt werden.
   */
  private _vehicleClassMwhMap = [
    {
      countryCode: CountryCodeEnum.germany,
      vehicleType: ThgVehicleTypes.M1,
      amountInKwh: 2000
    },
    {
      countryCode: CountryCodeEnum.germany,
      vehicleType: ThgVehicleTypes.N1,
      amountInKwh: 3000
    },
    {
      countryCode: CountryCodeEnum.germany,
      vehicleType: ThgVehicleTypes.other,
      amountInKwh: 2000
    },
    {
      countryCode: CountryCodeEnum.germany,
      vehicleType: ThgVehicleTypes.N2,
      amountInKwh: 20600
    },
    {
      countryCode: CountryCodeEnum.germany,
      vehicleType: ThgVehicleTypes.N3,
      amountInKwh: 33400
    },
    {
      countryCode: CountryCodeEnum.germany,
      vehicleType: ThgVehicleTypes.M3,
      amountInKwh: 72000
    },
    {
      countryCode: CountryCodeEnum.austria,
      vehicleType: ThgVehicleTypes.M1,
      amountInKwh: 1500
    }
  ];

  /**
   * Berechnet den Preis basierend auf Fahrzeugklasse, Land und Payout-Konfiguration.
   * Liefert 0 zurück, falls keine passenden Daten gefunden werden oder eine unbekannte Einheit vorliegt.
   */
  get price(): number {
    const { unit, revenue } = this.thg.payoutConfiguration;
    const vehicleClass = this.thg.registration.vehicleClass;
    const countryCode = this.thg.countryCode;

    // Finde den passenden Eintrag in der Map basierend auf Fahrzeugklasse und Land
    const vehicleData = this._vehicleClassMwhMap.find(
      v => v.vehicleType === vehicleClass && v.countryCode === countryCode
    );

    if (!vehicleData) {
      return 0;
    }

    const amountInKwh = vehicleData.amountInKwh;

    switch (unit) {
      case UnitEnum.KWH:
        return revenue * amountInKwh;
      case UnitEnum.MWH:
        return revenue * (amountInKwh / 1000);
      case UnitEnum.PIECE:
        return revenue;
      default:
        return 0;
    }
  }

  /**
   * Gibt den berechneten Preis als formatierten String zurück.
   * - Ist die Fahrzeugklasse nicht vorhanden, leer oder nicht in der Map vorhanden, wird
   *   "fahrzeugklasse in Prüfung" zurückgegeben.
   * - Ist der Preis 0, wird "Preis steht noch nicht fest" zurückgegeben.
   */
  get priceAndPromotionAsString(): string {
    const vehicleClass = this.thg.registration.vehicleClass;
    if (!vehicleClass || vehicleClass === "") {
      return $t("components.thg.ThgPriceHelper.vehicleClassPending");
    }
    // Überprüfe, ob ein passender Eintrag in der Map vorhanden ist
    const matchingVehicleData = this._vehicleClassMwhMap.find(
      v => v.vehicleType === vehicleClass && v.countryCode === this.thg.countryCode
    );
    if (!matchingVehicleData) {
      return $t("components.thg.ThgPriceHelper.vehicleClassPending");
    }

    if (this.price === 0) {
      return $t("components.thg.ThgPriceHelper.priceNotSet");
    }

    const computedPrice = this.price + (this.thg?.promotionConfiguration?.value || 0);
    return computedPrice.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });
  }
}
