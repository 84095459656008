


























































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThgMyVehicle } from "@/models/thg-my-vehicle.entity";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import ThgQuickCheckout from "./ThgQuickCheckout.vue";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    ThgQuickCheckout
  }
})
export default class ThgMyVehicleRenewalOptionsSheet extends DarkModeHighlightMixin {
  @Prop()
  vehicle!: IThgMyVehicle;

  @Prop()
  thgs!: ThgThgMeViewModelGen[];

  isLoading = false;

  get latestQuickCheckoutOption(): ThgThgMeViewModelGen | undefined {
    for (const thg of this.thgs) {
      if (thg.quickCheckoutEnabledFor?.length) {
        return thg;
      }
    }
    return undefined;
  }

  async setAutoRenewal(value: boolean) {
    if (!this.vehicle) {
      return;
    }
    this.isLoading = true;
    this.vehicle.isAutoRenewal = value;
    await this.vehicle.update().catch(handleError);

    this.isLoading = false;
  }

  async reDecommission() {
    if (!this.vehicle) {
      return;
    }
    this.isLoading = true;
    this.vehicle.decommissioningDate = undefined;

    await this.vehicle.update().catch(handleError);

    this.isLoading = false;
  }
}
