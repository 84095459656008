

















































































































































































































































































































































































































































































































































































































import LayoutReport from "@/layouts/LayoutReport.vue";
import { Component, Vue } from "vue-property-decorator";
import { ConfigModule } from "@/store/modules/config";
import { PartnerEntity } from "@/models/partnerEntity";
import { ReportModule } from "@/store/modules/report.store";
import { AppContextEnum } from "@/lib/enum/appContext.enum";

@Component({
  components: { LayoutReport }
})
export default class ThgTerms extends Vue {
  get isProd() {
    return ConfigModule.isProd;
  }

  get partner(): PartnerEntity {
    return ReportModule?.partner;
  }

  get agbUrl(): string | undefined {
    return this.partner?.settings?.agbUrl?.link;
  }

  get maxVehicleCount() {
    return this.partner?.settings?.bookedServices?.maxVehicleCount ?? 25;
  }

  mounted() {
    if (this.agbUrl && AppContextEnum.THG_PORTAL !== ConfigModule.appContext) {
      window.open(this.agbUrl, "_self");
    }
  }
}
