























































import { PartnerColor } from "@/lib/partnerColor";
import { ThgChargingStationViewModelGen, ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { ChargingStationModule } from "@/store/modules/charging-station.store";
import { Component, Vue } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import ThgQuoteExportDialog from "./ThgQuoteExportDialog.vue";
import { MeterReadingModule } from "@/store/modules/meter-reading.store";
import Debug from "../utility/Debug.vue";
import ThgChargingStationFormDialog from "@/components/thg/ThgChargingStationFormDialog.vue";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";

@Component({
  components: {
    Debug,
    ThgQuoteExportDialog,
    LatestEntriesCardEmpty,
    ThgChargingStationFormDialog
  }
})
export default class extends Vue {
  search = "";
  selection: ThgChargingStationViewModelGen[] = [];

  partnerColor = new PartnerColor();
  thgStatusMap = thgStatusMap;

  get myChargingStation() {
    return ChargingStationModule.charingStations;
  }

  get formDialog(): Vue & { openDialog: () => void } {
    return this.$refs.chargingStationFormDialog as Vue & { openDialog: () => void };
  }

  get dateOptions() {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    return options;
  }

  get headers() {
    return [
      { text: this.$t("components.thg.ThgChargingStationOverviewCard.timestamp"), value: "timestamp.created" },
      { text: this.$t("components.thg.ThgChargingStationOverviewCard.name"), value: "name" },
      {
        text: this.$t("components.thg.ThgChargingStationOverviewCard.connectionPowerInKwh"),
        value: "connectionPowerInKwh"
      },
      { text: this.$t("components.thg.ThgChargingStationOverviewCard.address"), value: "address" },
      { text: this.$t("components.thg.ThgChargingStationOverviewCard.commissioningDate"), value: "commissioningDate" },
      { text: "", value: "controls", sortable: false }
    ];
  }

  get meterReadingHeaders() {
    return [
      { text: "", value: null },
      { text: this.$t("components.thg.ThgVehicleTable.table.status"), value: "status" },
      { text: this.$t("components.thg.ThgChargingStationOverviewCard.year"), value: "year" },
      {
        text: this.$t("components.thg.ThgChargingStationOverviewCard.meterReading.startDate"),
        value: "meterReading.startDate"
      },
      {
        text: this.$t("components.thg.ThgChargingStationOverviewCard.meterReading.endDate"),
        value: "meterReading.endDate"
      },
      {
        text: this.$t("components.thg.ThgChargingStationOverviewCard.meterReading.amountInKwh"),
        value: "meterReading.amountInKwh"
      }
    ];
  }

  meterReadingsByChargingStation(chargingStation: ThgChargingStationViewModelGen): ThgThgMeterReadingViewModelGen[] {
    const mr = MeterReadingModule.meterReadingsPerChargingStationLookup.get(chargingStation.id);
    if (!mr) {
      return [];
    }
    return mr;
  }

  filterAll(value: any, search: string, item: ThgChargingStationViewModelGen) {
    return JSON.stringify(item)
      .toLowerCase()
      .includes(search.trim());
  }

  openDialog() {
    this.formDialog.openDialog();
  }

  thgStatus(thgStatusEnum: any): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum) || {
        text: "components.thg.ThgStatusMap.UNDEFINED.text",
        textAlt: "components.thg.ThgStatusMap.UNDEFINED.textAlt",
        icon: "mdi-help",
        portalText: "components.thg.ThgStatusMap.UNDEFINED.portalText",
        color: "error"
      }
    );
  }

  goToChargingStation(item: ThgChargingStationViewModelGen) {
    this.$router.push({ path: `/charging-station/${item.id}` });
  }

  goToAddMeterReading(item: ThgChargingStationViewModelGen) {
    this.$router.push({ path: `/charging-station/${item.id}/meter-reading` });
  }
}
