var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-with-banner', {
    attrs: {
      "isProgressDone": true,
      "showFooter": true,
      "showLoginButton": _vm.showLoginButton
    },
    scopedSlots: _vm._u([{
      key: "banner",
      fn: function fn() {
        return [_c('v-img', {
          staticClass: "hidden-xs-only",
          attrs: {
            "src": _vm.headerImage,
            "height": "550"
          }
        }, [_c('v-overlay', {
          attrs: {
            "value": "true",
            "absolute": ""
          }
        }, [_c('v-container', {
          staticClass: "mx-auto"
        }, [_c('v-row', [_c('v-col', {
          staticClass: "ml-4"
        }, [_c('h1', {
          staticClass: "text-md-h2 text-sm-h3 text-h4 font-weight-medium mt-sm-8 mt-md-0",
          domProps: {
            "innerHTML": _vm._s(_vm.$t(_vm.slogan))
          }
        })])], 1), _c('v-row', {
          staticClass: "mt-8"
        }, [_c('v-col', {
          attrs: {
            "xl": "4",
            "lg": "5",
            "md": "6",
            "xs": "10",
            "sm": "8",
            "cols": "10"
          }
        }, [_c('v-btn', {
          staticClass: "ml-4",
          attrs: {
            "color": _vm.color,
            "id": "start",
            "elevation": "0",
            "block": "",
            "dark": "",
            "x-large": "",
            "data-test-report-start-desktop-only": ""
          },
          on: {
            "click": _vm.start
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.headerButtonText)) + " ")])], 1)], 1), _c('div', {
          staticClass: "text-caption mb-4 mt-4 mx-4"
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-shield-check")]), _vm._v(" " + _vm._s(_vm.$t("report.trustBanner.privacy")) + " "), _c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-lock")]), _vm._v(" " + _vm._s(_vm.$t("report.trustBanner.encryption")) + " "), _c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-check-decagram")]), _vm._v(" " + _vm._s(_vm.$t("report.trustBanner.datacenter")) + " ")], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "mt-md-4",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "6",
            "cols": "12"
          }
        }, [_c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-card-title', {
          staticClass: "text-h4 font-weight-black",
          style: "color:".concat(_vm.partnerColorDarkmode, "; word-break: break-word")
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")]), _c('v-card-text', {
          staticClass: "text-body-1 pt-4"
        }, _vm._l(_vm.items, function (item, i) {
          return _c('v-row', {
            key: i
          }, [_c('v-col', {
            attrs: {
              "cols": "1"
            }
          }, [_c('v-icon', [_vm._v(_vm._s(item.icon))])], 1), _c('v-col', {
            staticClass: "pl-6 pl-sm-2",
            attrs: {
              "cols": "11"
            }
          }, [_c('p', [_vm._v(_vm._s(_vm.$t(item.text)))])])], 1);
        }), 1), _c('v-card-actions', {
          staticClass: "px-4 py-4"
        }, [_c('login-register-button', {
          attrs: {
            "showRegisterButton": false,
            "htmlClass": "mr-2"
          }
        }), _c('v-btn', {
          attrs: {
            "color": _vm.color,
            "id": "start",
            "elevation": "0",
            "dark": "",
            "large": "",
            "data-test-report-start": ""
          },
          on: {
            "click": _vm.start
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.buttonText)) + " ")])], 1)], 1)], 1), _c('v-col', {
          attrs: {
            "md": "6",
            "cols": "12"
          }
        }, [_c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_vm.videoSrc ? _c('v-card-subtitle', {
          staticClass: "d-flex d-sm-none"
        }, [_vm._v("Lernen Sie uns kennen")]) : _vm._e(), _c('v-card-text', {
          staticClass: "text-center"
        }, [_vm.videoSrc ? _c('video', {
          staticStyle: {
            "max-width": "100%",
            "max-height": "100%"
          },
          attrs: {
            "controls": ""
          }
        }, [_c('source', {
          attrs: {
            "src": _vm.videoSrc,
            "type": "video/mp4"
          }
        })]) : _c('v-img', {
          staticClass: "ma-8 hidden-sm-and-down",
          attrs: {
            "src": _vm.imageSrc
          }
        })], 1)], 1)], 1)], 1), _c('query-params-list'), _c('language-dialog', {
          on: {
            "next": function next($event) {
              _vm.dialog = false;
            }
          },
          model: {
            value: _vm.dialog,
            callback: function callback($$v) {
              _vm.dialog = $$v;
            },
            expression: "dialog"
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }