var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "md": _vm.md,
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "showFooter": false
    }
  }, [_c('v-radio-group', {
    model: {
      value: _vm.isCompany,
      callback: function callback($$v) {
        _vm.isCompany = $$v;
      },
      expression: "isCompany"
    }
  }, [_c('v-sheet', {
    staticClass: "mb-2 py-n6",
    attrs: {
      "color": _vm.isCompany === true ? 'primary' : '',
      "outlined": "",
      "rounded": ""
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    },
    on: {
      "click": function click($event) {
        _vm.isCompany = true;
      }
    }
  }, [_c('v-list', [_c('v-list-item', [_c('v-list-item-avatar', {
    attrs: {
      "tile": "",
      "size": "60"
    }
  }, [_c('v-icon', [_vm._v("mdi-office-building")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("report.thgIsCompanyRequired.company")) + " ")])], 1), _c('v-list-item-action', [_c('v-radio', {
    attrs: {
      "value": true
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "mb-2 py-n6",
    attrs: {
      "color": _vm.isCompany === false ? 'primary' : '',
      "outlined": "",
      "rounded": ""
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    },
    on: {
      "click": function click($event) {
        _vm.isCompany = false;
      }
    }
  }, [_c('v-list', [_c('v-list-item', [_c('v-list-item-avatar', {
    attrs: {
      "tile": "",
      "size": "60"
    }
  }, [_c('v-icon', [_vm._v("mdi-account")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("report.thgIsCompanyRequired.private")) + " ")])], 1), _c('v-list-item-action', [_c('v-radio', {
    attrs: {
      "value": false
    }
  })], 1)], 1)], 1), !_vm.isCompany ? _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("report.thgIsCompanyRequired.privateInfo")) + " ")]) : _vm._e(), !_vm.isCompany ? _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.switchToWkthg
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("report.thgIsCompanyRequired.privateBtn")))])], 1) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }