/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgPageViewModelGen,
  ThgThgVehicleControllerGetAllPaginatedParamsGen,
  ThgThgVehicleViewModelGen,
  ThgUpdateVehicleDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Vehicles<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Vehicles
   * @name ThgVehicleControllerGetAllPaginated
   * @summary Get paginated thg vehicle data
   * @request GET:/vehicle
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgThgVehicleViewModelGen)[] })`
   */
  thgVehicleControllerGetAllPaginated = (
    query: ThgThgVehicleControllerGetAllPaginatedParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgThgVehicleViewModelGen[] }, any>({
      path: `/vehicle`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Vehicles
   * @name ThgVehicleControllerGetOne
   * @summary Gets a particular thg vehicle
   * @request GET:/vehicle/{id}
   * @secure
   * @response `200` `ThgThgVehicleViewModelGen` THG Vehicle that will be returned
   */
  thgVehicleControllerGetOne = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgThgVehicleViewModelGen, any>({
      path: `/vehicle/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Vehicles
   * @name ThgVehicleControllerUpdateOne
   * @summary Gets a particular thg vehicle
   * @request PATCH:/vehicle/{id}
   * @secure
   * @response `200` `ThgThgVehicleViewModelGen` THG Vehicle that will be returned
   */
  thgVehicleControllerUpdateOne = (id: string, data: ThgUpdateVehicleDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgThgVehicleViewModelGen, any>({
      path: `/vehicle/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
