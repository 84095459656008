


































































import ThgRatingForm from "@/components/rating/ThgRatingForm.vue";
import Notifications from "@/components/thg/Notifications.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import Debug from "@/components/utility/Debug.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { PartnerColor } from "@/lib/partnerColor";
import { UserModule } from "@/store/modules/me-user.store";
import { RatingMeModule } from "@/store/modules/rating-me.store";
import { RatingModule } from "@/store/modules/rating.store";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component, Vue, Watch } from "vue-property-decorator";
import i18n from "../../plugins/I18nPlugin";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";

@Component({
  components: {
    LayoutReport,
    Debug,
    Notifications,
    ThgRatingForm,
    ConfirmActionDialog
  }
})
export default class ThgSuccess extends Vue {
  verificationCode = "";
  isLoading = false;
  isDialogActive = false;
  valid = false;

  ThgCreateModule = ThgCreateModule;

  get user() {
    return UserModule.user;
  }

  mounted() {
    if (this.isAuthenticated) {
      this.openRatingDialog();
    }
  }

  closeDialog() {
    this.isDialogActive = false;
  }

  openRatingDialog() {
    if (RatingMeModule.askForFeedback) {
      this.isDialogActive = true;
    }
  }

  get color() {
    return this.partner.settings?.isVuetifyInPartnerColors ? getDefaultPartnerColor() : "success";
  }

  get isThrottled() {
    return RatingModule.isThrottled;
  }

  get ratingSubmitted() {
    return RatingModule.ratingSubmitted;
  }

  @Watch("isThrottled")
  onIsThrottledChange(newValue: boolean) {
    if (newValue) {
      this.isDialogActive = false;
    }
  }

  @Watch("ratingSubmitted")
  onSubmittedChange(newValue: boolean) {
    if (newValue) {
      this.isDialogActive = false;
    }
  }

  get partnerColor() {
    return new PartnerColor();
  }

  get textFieldLabel(): string {
    return String(i18n.tc("report.thgSuccess.textFieldLabel"));
  }

  get isAuthenticated(): boolean {
    return UserModule.isAuthenticated;
  }

  get description() {
    if (!this.isAuthenticated) {
      return String(i18n.tc("report.thgSuccess.description")) + ThgCreateModule.contact.email;
    } else {
      return String(i18n.tc("report.thgSuccess.descriptionAlt"));
    }
  }

  get partner() {
    return ReportModule.partner;
  }

  get title() {
    if (!this.isAuthenticated) {
      return String(i18n.tc("report.thgSuccess.title")) + ThgCreateModule.user.firstName + "!";
    } else {
      return String(i18n.tc("report.thgSuccess.titleAlt"));
    }
  }

  newCar() {
    this.$router.push({ path: "/" });
  }

  goToVehicle() {
    this.$router.push({ path: "/vehicle" });
  }

  async submit() {
    this.isLoading = true;
    try {
      const verificationCodeWithoutWhitespace = this.verificationCode.trim();
      await ThgCreateModule.verifyEmail(verificationCodeWithoutWhitespace);
      this.openRatingDialog();
      try {
        await UserModule.logIn({ username: ThgCreateModule.contact.email, password: ThgCreateModule.password });
        await UserModule.getUserInfo();
      } catch {
        await this.$router.push({ name: "Login" });
      }
    } catch (error) {
      this.$log.error(error);

      this.$toast.error(String(i18n.tc("report.thgSuccess.errorMsg")));

      await this.$router.push({ name: "Confirm" });
    } finally {
      ThgCreateModule.resetThg();
      this.isLoading = false;
    }
  }
}
