


















































import ThgCheckoutSustainableCard from "@/components/thg/ThgCheckoutSustainableCard.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component } from "vue-property-decorator";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";
import { GtagModule } from "@/store/modules/gtag.store";
import ThgProductsPriceCard from "./ThgProductsPriceCard.vue";
import ThgProductsPriceComboCard from "./ThgProductsPriceComboCard.vue";
import { IPriceConfig } from "@/store/models/thg/active-price";

type ProductConfig = {
  years: number[];
  title: {
    token: string;
    values: {
      year: number;
    };
  };
  prices: IPriceConfig[];
};

@Component({
  components: {
    LayoutReportStep,
    ThgCheckoutSustainableCard,
    ThgProductsPriceCard,
    ThgProductsPriceComboCard
  }
})
export default class ThgProducts extends ReportStepMixin implements IReportStep {
  screen = ReportScreenEnum.thgProducts;

  isLoading = false;

  get years() {
    return ThgCreateModule.years;
  }

  set years(years: number[]) {
    ThgCreateModule.setYears(years);
  }

  get uniqueHints() {
    const hints: string[] = [];

    for (const availableProduct of this.availableProducts) {
      for (const price of availableProduct.prices) {
        if (price.hint && !hints.includes(price.hint)) {
          hints.push(price.hint);
        }
      }
    }

    return hints;
  }

  numberToWord(num: number) {
    const germanWords = [
      "common.numbers.none",
      "common.numbers.one",
      "common.numbers.two",
      "common.numbers.three",
      "common.numbers.four",
      "common.numbers.five"
    ];

    if (num >= 0 && num <= 5) {
      return this.$t(germanWords[num]);
    } else {
      return num.toString();
    }
  }

  transformYearsToString(arr: number[]): string {
    if (arr.length === 0) {
      return "";
    } else if (arr.length === 1) {
      return arr[0].toString();
    } else if (arr.length === 2) {
      return arr.join(" & ");
    } else {
      const lastTwo = arr.slice(-2).join(" & ");
      const remaining = arr.slice(0, -2).join(", ");
      return `${remaining}, ${lastTwo}`;
    }
  }

  public header: IReportHeaderData = {
    title: "report.thgYearSelector.title",
    description: "report.thgYearSelector.description"
  };

  public instruction: IReportInstructionData = {
    title: "report.thgYearSelector.instructionTitle",
    text: "report.thgYearSelector.instructionText",
    pictures: [AssetEnum.charging],
    example: AssetEnum.charging,
    display: true
  };

  activeCardStyle(years: number[]) {
    if (JSON.stringify(this.years) === JSON.stringify(years)) {
      return "primary";
    }
    return "";
  }

  get isProductsAvailable() {
    return !!this.availableProducts.length;
  }

  get availableProducts() {
    return PartnerActiveOfferModule.priceConfig.getYearCombinationsByVehicleClass(
      ThgCreateModule.vehicleClass || ThgVehicleTypes.M1
    );
  }

  get company() {
    return ReportModule.partner.companyName;
  }

  get items() {
    return ThgCreateModule.yearSelections;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get isDone() {
    return !!this.years.length;
  }

  async fetchPrices() {
    try {
      if (!this.availableProducts.length) {
        this.isLoading = true;
        await PartnerActiveOfferModule.loadDocuments(ReportModule.partner.id);
      }

      if (!this.years.length && this.availableProducts.length) {
        this.years = this.availableProducts[0].years;
      }
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("report.thgProducts.loadingError"));
    }

    this.isLoading = false;
  }

  next() {
    GtagModule.dispatchViewItemEvent();
  }

  async mounted() {
    this.setCurrentStep();
    await this.fetchPrices();
  }
}
