































import LayoutReport from "@/layouts/LayoutReport.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { ThgFaqViewModelGen } from "@/services/thg/v1/data-contracts";
import { FaqModule } from "@/store/modules/faq.store";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutReport }
})
export default class ThgFrequentlyAskedQuestions extends Vue {
  isLoading = false;

  get color() {
    return getDefaultPartnerColor();
  }

  async mounted() {
    this.isLoading = true;

    try {
      await FaqModule.getAll({ partnerId: ReportModule.partner._id });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  get faqs() {
    const faqs = FaqModule.paginationList;

    if (faqs && faqs.length >= 1) {
      this.faqList.push(...faqs);
    }

    return this.faqList.filter(faq => faq.isActive);
  }

  get faqList(): ThgFaqViewModelGen[] {
    return [
      {
        question: "Was ist die THG-Quote?",
        answer:
          "THG-Quote steht für Treibhausgasminderungsquote. Jährlich gilt es für Unternehmen, die beispielsweise fossile Brennstoffe in Umlauf bringen, einen steigenden Prozentsatz an Emissionen zu reduzieren oder wenigstens zu kompensieren. Sollte diese auferlegte Quote nicht eingehalten werden, drohen Strafzahlungen. Die gesetzliche Grundlage für die THG-Quote findet sich im Bundes-Immissionsschutzgesetz (BImSchG) sowie in der 38. Bundes-Immissionsschutzverordnung (38. BImSchV).",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Wieso erhalte ich für mein E-Fahrzeug eine THG-Prämie?",
        answer:
          "Wenn du elektrisch fährst, sparst du CO2 ein. Diese Einsparungen können wir nun für dich vermarkten, da es seit 2022 eine neue Gesetzesgrundlage dafür gibt.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Wie hoch ist die THG-Prämie?",
        answer: "Die aktuellen Preise werden im Rahmen der Registrierung angezeigt.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Wie oft kann ich die THG-Prämie beantragen?",
        answer: "Du kannst die THG-Prämie für jedes vollelektrische Fahrzeug einmal pro Kalenderjahr beantragen.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Welche Unterlagen benötige ich zur Registrierung der THG-Quote?",
        answer:
          "Neben deinen persönlichen Daten benötigen wir von dir ein aktuelles Foto der Vorderseite deines Fahrzeugscheins (Zulassungsbescheinigung Teil 1). Das Foto kannst du bequem auf dieser Website hochladen.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Muss ich die Erlöse aus der THG-Quote als Privatperson versteuern?",
        answer:
          "Die Erlöse der THG-Quote müssen für Privatpersonen nicht versteuert werden. Bitte beachte noch unseren Hinweis: Das ist keine steuerliche Beratung oder eine Empfehlung für eine Anlageentscheidung; bei Fragen zu der Thematik empfehlen wir einen Steuerberater zu kontaktieren.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Können auch Firmenwagen für die THG-Quote registriert werden?",
        answer: "Ja, es können sowohl Privat- als auch Firmenfahrzeuge für die THG-Quote registriert werden.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Kann ich die THG-Quote auch für Leasing-Fahrzeuge beantragen?",
        answer: "Ja, die THG-Quote gilt auch für E-Fahrzeuge, die geleast werden.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Kann ich auch als Fahrer eines Hybridfahrzeugs von der THG-Quote profitieren?",
        answer: "Leider nein. Derzeit sind weder Hybrid- noch Plug-In-Hybridfahrzeuge quotenberechtigt.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Wann und wie erhalte ich die Prämie der THG-Quote?",
        answer:
          "Die Prüfung beim Umweltbundesamt nimmt mehrere Monate in Anspruch. Informationen zum Antragsstatus findest du in der Fahrzeugübersicht im Loginbereich.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Ist die Höhe der Prämie abhängig vom Fahrzeugmodell?",
        answer:
          "Nein, aktuell gibt es keine Modellunterschiede für PKWs - größer oder kleiner macht keinen Unterschied, außer wir sprechen über Elektro-LKW oder Busse.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Bis wann gibt es die THG-Quote für E-Fahrzeuge?",
        answer:
          "Die aktuelle Gesetzesgrundlage sieht vor, dass der THG-Quotenhandel mit Zertifikaten von E-Fahrzeugen bis zum Jahr 2030 möglich ist. Eine Verlängerung ist jedoch wahrscheinlich.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Ich möchte meinen Daten löschen. Geht das?",
        answer:
          "Selbstverständlich können wir deine Daten löschen. Eine formlose E-Mail reicht dazu aus, sofern nicht gesetzliche Aufbewahrungspflichten dem widersprechen.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      },
      {
        question: "Sind meine Daten sicher?",
        answer:
          "Alle Daten werden ausschließlich in deutschen Rechenzentren gespeichert und können nur mit entsprechender Zugriffsberechtigung abgerufen werden. Besonders sensible Daten, wie z. B. die Bankverbindungen, werden zusätzlich besonders verschlüsselt gespeichert. Die Plattform ist DSGVO-konform und unterliegt laufenden Prüfungen.",
        id: "1",
        partnerId: ReportModule.partner._id,
        isActive: true,
        timestamp: {
          created: "",
          lastModified: "",
          modified: []
        }
      }
    ];
  }
}
