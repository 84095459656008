/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ThgHttpClientProvider } from "../thg-http-client.provider";
import { MyVehicles } from "../v1/MyVehicles";
import {
  ThgPageViewModelGen,
  ThgThgVehicleMeControllerGetAllPaginatedParamsGen,
  ThgThgVehicleViewModelGen,
  ThgUpdateMyVehicleDtoGen
} from "../v1/data-contracts";

/**
 * Service for interacting with the thg vehicle endpoint.
 */
export class ThgVehicleMeService {
  private readonly vehicles: MyVehicles;

  constructor(clientProvider: ThgHttpClientProvider) {
    this.vehicles = new MyVehicles(clientProvider.client());
  }

  /**
   * Retrieves all thg vehicle records
   *
   * @param query - Parameters for retrieving thg vehicle data.
   * @returns A paginated view model containing thg vehicle data.
   */
  async findAll(
    query: ThgThgVehicleMeControllerGetAllPaginatedParamsGen
  ): Promise<ThgPageViewModelGen & { data?: ThgThgVehicleViewModelGen[] | undefined }> {
    return (await this.vehicles.thgVehicleMeControllerGetAllPaginated(query)).data;
  }

  /**
   * Retrieves all thg vehicle records
   *
   * @param query - Parameters for retrieving thg vehicle data.
   * @returns A paginated view model containing thg vehicle data.
   */
  async findOne(id: string): Promise<ThgThgVehicleViewModelGen> {
    return (await this.vehicles.thgVehicleMeControllerGetOne(id)).data;
  }

  /**
   * Updates my thg vehicle
   *
   * @param query - Parameters for retrieving thg vehicle data.
   * @returns A paginated view model containing thg vehicle data.
   */
  async updateOne(id: string, dto: ThgUpdateMyVehicleDtoGen): Promise<ThgThgVehicleViewModelGen> {
    return (await this.vehicles.thgVehicleMeControllerUpdateOne(id, dto)).data;
  }
}

export default new ThgVehicleMeService(new ThgHttpClientProvider());
