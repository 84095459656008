


































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThgMyVehicle } from "@/models/thg-my-vehicle.entity";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";
import { ThgMyVehicleModule } from "@/store/modules/thg-vehicle-me.store";
import { Component, Prop } from "vue-property-decorator";
import ImageDialog from "../utility/ImageDialog.vue";
import NumberplateForm from "../utility/NumberplateForm.vue";
import ThgMyVehicle from "./ThgMyVehicle.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: {
    NumberplateForm,
    ImageDialog,
    ThgMyVehicle
  }
})
export default class ThgMyVehicles extends DarkModeHighlightMixin {
  @Prop()
  myThgs!: ThgThgMeViewModelGen[];

  filter: {
    isAutorenewal: boolean | null;
    isDeregistered: boolean | null;
  } = {
    isAutorenewal: null, // Standard: keine Auswahl
    isDeregistered: null // Standard: keine Auswahl
  };

  isLoading = false;

  get partner() {
    return PartnerModule.partner;
  }

  get partnerColor() {
    return this.partner.settings?.isVuetifyInPartnerColors ? getDefaultPartnerColor() : "success";
  }

  get activeVehicleFilterColor() {
    if (!this.filter.isDeregistered) {
      return this.partner.settings?.isVuetifyInPartnerColors ? getDefaultPartnerColor() : "success";
    }

    return "";
  }

  get decommissionedVehicleFilterColor() {
    if (this.filter.isDeregistered) {
      return this.partner.settings?.isVuetifyInPartnerColors ? getDefaultPartnerColor() : "success";
    }

    return "";
  }

  get groupedThgs(): { vehicle: IThgMyVehicle | undefined; thgs: ThgThgMeViewModelGen[] }[] {
    // Gruppierung anhand der vehicleId
    const groups = this.myThgs.reduce((groups, thg) => {
      let group = groups.find(g => (g.vehicle ? g.vehicle.id === thg.vehicleId : false));

      if (!group) {
        const vehicle = this.myVehicles.find(v => v.id === thg.vehicleId);
        group = { vehicle, thgs: [] };
        groups.push(group);
      }

      group.thgs.push(thg);
      return groups;
    }, [] as { vehicle: IThgMyVehicle | undefined; thgs: ThgThgMeViewModelGen[] }[]);

    // Sortierung innerhalb der Gruppen nach Jahr
    groups.forEach(group => {
      group.thgs.sort((a, b) => b.year - a.year);
    });

    // Sortieren der Gruppen nach Erstellungsdatum oder Jahr
    let sortedGroups = groups.sort((a, b) => {
      if (a.vehicle && b.vehicle) {
        const timestampDiff =
          new Date(b.vehicle.timestamp.created).getTime() - new Date(a.vehicle.timestamp.created).getTime();
        if (timestampDiff !== 0) {
          return timestampDiff;
        }
      } else if (a.vehicle) {
        return -1; // a hat Fahrzeug, b nicht → a vor b
      } else if (b.vehicle) {
        return 1; // b hat Fahrzeug, a nicht → b vor a
      }
      const aMaxYear = a.thgs.length > 0 ? a.thgs[0].year : 0;
      const bMaxYear = b.thgs.length > 0 ? b.thgs[0].year : 0;
      return bMaxYear - aMaxYear;
    });

    // **Filter für isDeregistered, nur wenn ein Wert gesetzt ist**
    if (this.filter.isDeregistered !== null && this.filter.isDeregistered !== undefined) {
      sortedGroups = sortedGroups.filter(group => !!group.vehicle?.decommissioningDate === this.filter.isDeregistered);
    }

    return sortedGroups;
  }

  thgPerVehicleId(vehicleId: string) {
    return this.myThgs.filter(thg => thg.vehicleId === vehicleId);
  }

  async mounted() {
    await this.loadAll();
  }

  get myVehicles() {
    return ThgMyVehicleModule.entities;
  }

  async loadAll() {
    try {
      ThgMyVehicleModule.setItemsPerPage(500);
      ThgMyVehicleModule.fetchFirstPage({});
    } catch {
      this.$toast.error(this.$t("report.thgVehicleOverview.errorTxt").toString());
    } finally {
      this.isLoading = false;
    }
  }
}
