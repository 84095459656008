




























































































import DescriptionCard from "@/components/thg/DescriptionCard.vue";
import KnownFormCard from "@/components/thg/KnownFormCard.vue";
import ThgPriceOverview from "@/components/thg/ThgPriceOverview.vue";
import ThgProcessDescriptionCard from "@/components/thg/ThgProcessDescriptionCard.vue";
import LoaderReport from "@/components/utility/LoaderReport.vue";
import LayoutReportWithBanner from "@/layouts/LayoutReportWithBanner.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/I18nPlugin";
import { GtagModule } from "@/store/modules/gtag.store";
import LoginRegisterButton from "@/components/utility/LoginRegisterButton.vue";

@Component({
  components: {
    LayoutReportWithBanner,
    LoaderReport,
    KnownFormCard,
    DescriptionCard,
    ThgProcessDescriptionCard,
    ThgPriceOverview,
    LoginRegisterButton
  }
})
export default class ThgHome extends Vue {
  screenName = ReportScreenEnum.thghome;

  get loading() {
    return ReportModule.loading && PartnerActiveOfferModule.loading;
  }

  get isWhiteLabelPartner(): boolean {
    return PartnerModule?.partner.partnerType === PartnerTypeEnum.THG_WHITE_LABEL_QUOTE_AS_A_SERVICE;
  }

  get showTrustBanner(): boolean {
    return PartnerModule?.partner.partnerType === PartnerTypeEnum.THG_WHITE_LABEL_MINT_FUTURE;
  }

  get headerImage() {
    if (PartnerModule?.partner?.settings?.reportSettings?.headerImageUrl) {
      return PartnerModule?.partner?.settings?.reportSettings?.headerImageUrl;
    } else if (PartnerModule?.partner?.settings?.headerImageUrl) {
      return PartnerModule?.partner?.settings?.headerImageUrl;
    } else {
      return "https://static.mmmint.ai/wirkaufendeinethg/header-image.png";
    }
  }

  get placeFillerImage() {
    if (PartnerModule?.partner?.settings?.reportSettings?.welcomeImageUrl) {
      return PartnerModule?.partner?.settings?.reportSettings?.welcomeImageUrl;
    }

    if (PartnerModule?.partner.partnerType === PartnerTypeEnum.THG_WHITE_LABEL_MINT_FUTURE) {
      return "https://static.mmmint.ai/public-thg/2023-08-17-header-64857b7c0fb0eec7e4e4b315.png";
    }
    return "https://static.mmmint.ai/public-thg/2023-08-17-header-a638bbe6f267f72125739d7c.png";
  }

  get placeFillerImageLazy() {
    if (PartnerModule?.partner?.settings?.reportSettings?.welcomeImageUrl) {
      return PartnerModule?.partner?.settings?.reportSettings?.welcomeImageUrl;
    }

    if (PartnerModule?.partner.partnerType === PartnerTypeEnum.THG_WHITE_LABEL_MINT_FUTURE) {
      return "https://static.mmmint.ai/public-thg/2023-08-17-header-e9ed43c8d2d3e16a61780cc9.png";
    }
    return "https://static.mmmint.ai/public-thg/2023-08-17-header-266e46bae8e17594b0892c43.png";
  }

  get logos() {
    return [
      "https://wirkaufendeinethg.de/images/trustpartner/bvmw.svg",
      "https://wirkaufendeinethg.de/images/trustpartner/teslaausstatter-blue.png",
      "https://wirkaufendeinethg.de/images/trustpartner/wfo.webp",
      "https://wirkaufendeinethg.de/images/trustpartner/provi.svg",
      "https://wirkaufendeinethg.de/images/trustpartner/vgh.svg",
      "https://wirkaufendeinethg.de/images/trustpartner/acv.svg",
      "https://wirkaufendeinethg.de/images/trustpartner/oeffentliche.svg",
      "https://wirkaufendeinethg.de/images/trustpartner/efahrer.svg",
      "https://wirkaufendeinethg.de/images/trustpartner/oeffentliche-oldenburg.svg",
      "https://wirkaufendeinethg.de/images/trustpartner/ean.png"
    ];
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get partnerColorDarkmode() {
    return this.$vuetify.theme.dark ? "white" : this.color;
  }

  get title() {
    return i18n.t("report.thgHome.headline");
  }

  get knownFromText(): string {
    return String(i18n.tc("report.thgHome.knownFrom"));
  }

  /**
   * Returns a subtitle if partner has a form title configured and its not "-"
   */
  get subtitle(): string {
    if (ReportModule.partner?.settings?.formTitle && ReportModule.partner?.settings?.formTitle !== "-") {
      return ReportModule.partner?.settings?.formTitle;
    }

    return "";
  }

  get customSubtitle() {
    return ReportModule?.partner?.settings?.reportSettings?.welcomeText || [];
  }

  get subTitle(): string {
    if (!this.isAuthenticated) {
      return String(i18n.t("report.thgHome.subTitle"));
    } else {
      return String(i18n.tc("report.thgHome.subTitleAlt"));
    }
  }

  get subTitle2(): string {
    if (!this.isAuthenticated) {
      return String(i18n.t("report.thgHome.subTitle2"));
    } else {
      return String(i18n.tc("report.thgHome.subTitle2"));
    }
  }

  get submitButtonText(): string {
    if (!this.isAuthenticated) {
      return String(i18n.tc("report.thgHome.btnText"));
    } else {
      return String(i18n.tc("report.thgHome.btnTextAlt"));
    }
  }

  get isAuthenticated(): boolean {
    return UserModule.isAuthenticated;
  }

  start() {
    if (ReportModule.nextScreen) {
      const next = ReportModule.nextScreen;
      ReportModule.setStep(next);

      GtagModule.dispatchSelectContentEvent();
      this.$router.push({ path: `/${next}` });
    }
  }

  async mounted() {
    ReportModule.setStep(this.screenName);
  }
}
