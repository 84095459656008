



























































































































































































import DropAreaMulti from "@/components/files/DropAreaMulti.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import { convertDataURItoFile } from "@/lib/files/imageResizer";
import { isPdf } from "@/lib/files/PdfHelper";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { convertPdfToImg } from "@/lib/utility/convertPdfToImage";
import { ThgCreateThgVehicleDtoGen, ThgThgViewModelGen } from "@/services/thg/v1/data-contracts";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { PayoutOptionEnum } from "@/views/thg/enum/payout-option.enum";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ThgDialogYearSelection from "./ThgDialogYearSelection.vue";
import thgService from "@/services/thg/services/thgService";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportModule } from "@/store/modules/report.store";
import { PartnerModule } from "@/store/modules/partner";
import { TranslateResult } from "vue-i18n";
const PDFJSWorker = () => import("pdfjs-dist/legacy/build/pdf.worker.entry");
const pdfJs = () => import("pdfjs-dist/legacy/build/pdf");

enum ThgImportEnum {
  /**
   * Select which GHG year / price
   */
  SELECT,

  /**
   * Drop field for files
   */
  DROP,

  /**
   * Confirm the dropped files
   */
  CONFIRM,

  /**
   * Upload all requests
   */
  UPLOAD,

  /**
   * Display a success screen with summary
   */
  SUCCESS
}

type ImportThg = {
  /**
   * Used for indicating a THG request has been processed, on true `result` should be filled.
   */
  isUploaded: boolean;

  /**
   * Used for loading animation, when THG request is being processed
   */
  isLoading: boolean;

  /**
   * The file dropped and being processed
   */
  file: File;

  /**
   * The page (for pdf documents) of the file
   */
  page?: number;

  /**
   * Internal image url for display purposes
   */
  imageUrl?: string | ArrayBuffer | null;

  /**
   * The request dto, filled on mounted based on dropped files.
   * Numberplate is the file name
   */
  dto?: ThgCreateThgVehicleDtoGen;

  /**
   * Already uploaded images of the request
   */
  imageUploaded?: IImageUploaded;

  /**
   * The completed THG request, `isUploaded` is true
   */
  result?: ThgThgViewModelGen;

  /**
   * Error state
   */
  isError?: boolean;

  /**
   * The error message
   */
  error?: Error;
};

@Component({
  components: {
    ThgDialogYearSelection,
    ConfirmActionDialog,
    DropAreaMulti,
    Tooltip
  }
})
export default class ThgImportDialog extends Vue implements IDialog {
  @Prop({ default: false })
  isDialogActive!: boolean;

  window = ThgImportEnum.SELECT;

  loading = false;
  loadingProgress = 0;

  files: File[] = [];
  thgs: ImportThg[] = [];

  accept = ".pdf, .jpg, .jpeg, .png";
  maxFiles = 200;

  isAllUploaded = false;

  /**
   * Feature flag to enable single request
   */
  isSingleRequestPossible = false;

  imagePreview: string | ArrayBuffer | null | undefined = null;
  isShowImagePreviewDialog = false;

  @Watch("uploadedThgCount")
  watchUploadedThgCount() {
    this.isAllUploaded = this.thgs.length === this.uploadedThgCount;

    if (this.isAllUploaded) {
      this.loading = false;
    }
  }

  close() {
    this.$emit("close");
    this.window = ThgImportEnum.SELECT;
    this.dialogModel = false;
    this.thgs = [];
    this.files = [];
  }

  open(): void {
    this.window = ThgImportEnum.SELECT;
    this.dialogModel = true;
  }

  prev() {
    switch (this.window) {
      case ThgImportEnum.SELECT:
        this.close();
        break;

      case ThgImportEnum.DROP:
        this.window = ThgImportEnum.SELECT;
        this.thgs = [];
        this.files = [];
        break;

      case ThgImportEnum.CONFIRM:
        this.window = ThgImportEnum.DROP;
        break;

      case ThgImportEnum.UPLOAD:
        this.window = ThgImportEnum.CONFIRM;
        break;

      default:
        this.close();
        break;
    }
  }

  async next() {
    switch (this.window) {
      case ThgImportEnum.SELECT:
        this.window = ThgImportEnum.DROP;
        break;

      case ThgImportEnum.DROP:
        this.window = ThgImportEnum.CONFIRM;
        break;

      case ThgImportEnum.CONFIRM:
        this.loading = true;

        for (const thg of this.thgs.filter(t => !t.isUploaded)) {
          this.submitThg(thg);
        }

        this.window = ThgImportEnum.UPLOAD;
        break;

      case ThgImportEnum.UPLOAD:
        this.window = ThgImportEnum.SUCCESS;
        break;

      case ThgImportEnum.SUCCESS:
        this.$emit("imported");
        this.close();
        break;

      default:
        this.window = ThgImportEnum.DROP;
        break;
    }
  }

  get rightDisabled(): boolean {
    switch (this.window) {
      case ThgImportEnum.SELECT:
        return ThgCreateModule.years.length <= 0;

      case ThgImportEnum.DROP:
        return this.files.length <= 0;

      case ThgImportEnum.CONFIRM:
        return this.thgs.length <= 0;

      case ThgImportEnum.UPLOAD:
        return !this.isAllUploaded;

      default:
        return false;
    }
  }

  get rightText(): TranslateResult {
    switch (this.window) {
      case ThgImportEnum.CONFIRM:
        return this.$t("report.thgVehicleOverview.thgImportDialog.confirmBtn", { count: this.thgs.length });
      default:
        return this.$t("next");
    }
  }

  get leftDisabled() {
    switch (this.window) {
      case ThgImportEnum.UPLOAD:
        return true;

      case ThgImportEnum.SUCCESS:
        return true;

      default:
        return false;
    }
  }

  get ThgImportEnum() {
    return ThgImportEnum;
  }

  get partnerId(): string {
    return ReportModule.partner.id || ReportModule.partner._id || PartnerModule.partner.id;
  }

  get noImages() {
    if (!this.files.length) {
      return true;
    }
    return false;
  }

  get headers() {
    return [
      {
        text: "Numberplate",
        value: "dto.numberplate"
      },
      {
        text: "Image",
        value: "image"
      },
      { text: "Actions", value: "actions", sortable: false }
    ];
  }

  get headersUpload() {
    return [
      {
        text: "Numberplate",
        value: "dto.numberplate"
      },

      { text: "Actions", value: "actions", sortable: false }
    ];
  }

  get prices() {
    return PartnerActiveOfferModule.prices;
  }

  get years(): number[] {
    return this.prices.map(p => p.year);
  }

  get selectedYears() {
    return ThgCreateModule.years;
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.$emit("update:isDialogActive", v);
  }

  get uploadedThgCount() {
    return this.thgs.reduce((count, t) => (t.isUploaded ? count + 1 : count), 0);
  }

  get toBeUploaded() {
    return this.thgs.filter(t => t.isUploaded);
  }

  async prepareDtos() {
    for (const file of this.files) {
      if (isPdf(file)) {
        this.loading = true;

        try {
          const pdf: Uint8Array = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = e => {
              resolve(new Uint8Array(e.target?.result as any));
            };
          });

          const images = await convertPdfToImg(pdf);

          const dotIndex = file.name.indexOf(".");

          const name = file.name.slice(0, dotIndex);
          const extension = file.name.slice(dotIndex); // Includes the dot

          const files = await Promise.all(
            images.map((image, index) => convertDataURItoFile(image, name + "_" + index + extension))
          );

          for (let i = 0; i < files.length; i++) {
            const f = files[i];
            this.addDto(f, i + 1);
          }
        } catch (error) {
          this.$log.error(error);
          this.$toast.error("Fehler beim Verarbeiten der PDF");
        } finally {
          this.loading = false;
        }
      } else {
        this.addDto(file);
      }
    }
  }

  submitThg(thg: ImportThg) {
    if (!thg.dto) {
      this.$log.error("submit", thg);
      return;
    }

    thg.isError = false;
    thg.error = undefined;

    thg.isLoading = true;
    this.$log.debug("submit", thg);

    thgService
      .addImage(thg.file, ReportImageType.registration, this.partnerId)
      .then(r => {
        thg.imageUploaded = r;

        if (thg.dto) {
          thg.dto.registrationImages = [thg.imageUploaded.uploadId];

          thgService
            .addThg(this.partnerId, thg.dto)
            .then(r => {
              thg.result = r.data;
              thg.isUploaded = true;
            })
            .catch(e => {
              thg.isError = true;
              thg.error = e;
            })
            .finally(() => (thg.isLoading = false));
        }
      })
      .catch(e => {
        thg.isError = true;
        thg.error = e;
      })
      .finally(() => (thg.isLoading = false));
  }

  popItem(index: number) {
    const popped = this.thgs.splice(index, 1);
    const poppedFile = this.files.splice(index, 1);
    this.$log.debug("pop", popped, poppedFile);
  }

  addDto(file: File, page?: number) {
    const thg: ImportThg = {
      dto: {
        numberplate: file.name.split(".")[0],
        impactType: ImpactTypeEnum.payout,
        impactFactor: 0,
        registrationImages: [],
        isConfirmed: true,
        years: ThgCreateModule.years,
        payoutOption: PayoutOptionEnum.REGULAR
      },
      file: file,
      page: page,
      isUploaded: false,
      isLoading: false
    };

    const reader = new FileReader();
    reader.onload = () => {
      thg.imageUrl = reader.result;
    };
    reader.readAsDataURL(file);

    this.thgs.push(thg);
  }

  showImagePreview(thg: ImportThg) {
    this.isShowImagePreviewDialog = true;
    this.imagePreview = thg.imageUrl;
  }

  closeImagePreview() {
    this.isShowImagePreviewDialog = false;
    this.imagePreview = null;
  }

  async mounted() {
    const GlobalWorkerOptions = (await pdfJs()).GlobalWorkerOptions;
    GlobalWorkerOptions.workerSrc = await PDFJSWorker();
  }
}
