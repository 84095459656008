



























































































































import ProfileFormEmbedded from "@/components/profile/ProfileFormEmbedded.vue";
import ProfileFormFields, { ProfileFormFieldsDisplayOptions } from "@/components/profile/ProfileFormFields.vue";
import NumberplateForm from "@/components/utility/NumberplateForm.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { emailRule, germanZipRule, noNumberRule, phoneRule } from "@/lib/rules/contactRule";
import { endsWithCharacterRule } from "@/lib/rules/endsWithCharacterRule";
import { ibanRule } from "@/lib/rules/ibanRule";
import { noWhiteSpaceRule } from "@/lib/rules/noWhiteSpaceRule";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { MrfiktivAddressGen, MrfiktivUpdateUserDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { GtagModule } from "@/store/modules/gtag.store";
import { UserModule } from "@/store/modules/me-user.store";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    NumberplateForm,
    LayoutReportStep,
    ProfileFormFields,
    ProfileFormEmbedded
  }
})
export default class ThgContactDataAt extends ReportStepMixin implements IReportStep {
  public md = 4;

  screen = ReportScreenEnum.thgcontactat;

  isUpdatingUser = false;
  /**
   * Track whether the authenticated user has populated the required fields(address and tax number)
   * Let the user to next pages even if the request for update user fails.(permit retries)
   * If the subform is not visible for a user, this field will be true and the user can go to next pages.
   */
  isProfileFormRequiredPopulated = this.isAuthenticated
    ? this.authenticatedUserHasAddress && this.authenticatedUserHasTaxNumber
    : true;

  /**
   * Configure which profile information fields are visible.
   * We require the tax number if a user is company and the address if not
   * populated already.
   */
  get profileFieldsDisplayOptions(): ProfileFormFieldsDisplayOptions {
    return {
      isGeneralEnabled: false,
      isCompanyEnabled: !this.authenticatedUserHasTaxNumber,
      isContactEnabled: false,
      isAddressEnabled: !this.authenticatedUserHasAddress
    };
  }

  userInfo = {
    get company(): string {
      return ThgCreateModule.company;
    },
    set company(value: string) {
      ThgCreateModule.setCompany(value);
    },

    get isCompany(): boolean {
      return ThgCreateModule.isCompany;
    },
    set isCompany(value: boolean) {
      ThgCreateModule.setIsCompany(value);
    },

    get taxnumber(): string {
      return ThgCreateModule.taxnumber;
    },
    set taxnumber(value: string) {
      ThgCreateModule.setTaxnumber(value);
    },

    get isTaxDeductible(): boolean {
      return ThgCreateModule.isTaxDeductible;
    },
    set isTaxDeductible(value: boolean) {
      ThgCreateModule.setIsTaxDeductible(value);
    },

    get firstName(): string {
      return ThgCreateModule.user.firstName;
    },
    set firstName(value: string) {
      ThgCreateModule.setUser({ firstName: value });
    },

    get lastName(): string {
      return ThgCreateModule.user.lastName;
    },
    set lastName(value: string) {
      ThgCreateModule.setUser({ lastName: value });
    },

    contact: {
      get email(): string {
        return ThgCreateModule.contact.email;
      },
      set email(value: string) {
        ThgCreateModule.setContact({ email: value });
      },

      get phone(): string {
        return ThgCreateModule.contact.phone;
      },
      set phone(value: string) {
        ThgCreateModule.setContact({ phone: value });
      }
    },
    address: {
      get street(): string {
        return ThgCreateModule.address.street;
      },
      set street(value: string) {
        ThgCreateModule.setAddress({ street: value });
      },

      get zip(): string {
        return ThgCreateModule.address.zip;
      },
      set zip(value: string) {
        ThgCreateModule.setAddress({ zip: value });
      },

      get city(): string {
        return ThgCreateModule.address.city;
      },
      set city(value: string) {
        ThgCreateModule.setAddress({ city: value });
      },

      get state(): string {
        return ThgCreateModule.address.state;
      },
      set state(value: string) {
        ThgCreateModule.setAddress({ state: value });
      },

      get countryCode(): string {
        return ThgCreateModule.address.countryCode;
      },
      set countryCode(value: string) {
        ThgCreateModule.setAddress({ countryCode: value });
      }
    }
  };

  get countryCode() {
    return ReportModule?.partner?.countryCode;
  }

  // Getters
  get header(): IReportHeaderData {
    if (!this.isAuthenticated) {
      return {
        title: "report.thgContactData.notAuthenticatedTitle",
        description: "report.thgContactData.notAuthenticatedDescription"
      };
    } else {
      return {
        title: "report.thgContactData.title",
        description: "report.thgContactData.description"
      };
    }
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  get showBankingInfo(): boolean {
    return ThgCreateModule.showBankingInfo;
  }

  get numberplate(): string {
    return ThgCreateModule.numberplate;
  }

  set numberplate(value: string) {
    ThgCreateModule.setNumberplate(value);
  }

  get user(): any {
    return ThgCreateModule.user;
  }

  get authenticatedUser() {
    return UserModule.user;
  }

  get authenticatedUserHasTaxNumber() {
    if (!this.authenticatedUser) {
      return false;
    }

    // Skip check for taxnumber for people
    if (!this.authenticatedUser.isCompany) {
      return true;
    }

    return !!this.authenticatedUser.taxnumber;
  }

  get authenticatedUserHasAddress(): boolean {
    if (!this.authenticatedUser) {
      return false;
    }

    const address = this.authenticatedUser.address as MrfiktivAddressGen;

    // Country code is optional, so don't check for it
    return !!(address && address.city && address.street && address.zip);
  }

  async updateUser(user: MrfiktivUpdateUserDtoGen) {
    try {
      this.isUpdatingUser = true;
      this.isProfileFormRequiredPopulated = false;
      await UserModule.updateUser({ id: this.authenticatedUser._id, updateUserDto: user });
      this.$toast.success(this.$t("components.profile.EditProfileDialog.success"));
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("components.profile.EditProfileDialog.errorMsg"));
    } finally {
      this.isUpdatingUser = false;
      this.isProfileFormRequiredPopulated = true;
    }
  }

  get contact(): any {
    return ThgCreateModule.contact;
  }

  get address(): any {
    return ThgCreateModule.address;
  }

  get banking(): any {
    return ThgCreateModule.banking;
  }

  get isResubmitAddress(): boolean {
    return ThgCreateModule.isResubmitAddress;
  }

  set isResubmitAddress(isResubmitting: boolean) {
    ThgCreateModule.setisResubmitAddress(isResubmitting);
  }

  get isResubmittingTaxnumber(): boolean {
    return ThgCreateModule.isResubmitTaxnumber;
  }

  set isResubmittingTaxnumber(isResubmitting: boolean) {
    ThgCreateModule.setIsResubmitTaxnumber(isResubmitting);
  }

  get isResubmittingBankingData(): boolean {
    return ThgCreateModule.isResubmitBankingInformation;
  }

  set isResubmittingBankingData(isResubmitting: boolean) {
    ThgCreateModule.setIsResubmitBankingInformation(isResubmitting);
  }

  updateBankingName(e: any) {
    ThgCreateModule.setBanking({ name: e.target.value });
  }

  updateBankingIban(e: any) {
    ThgCreateModule.setBanking({ iban: e.target.value });
  }

  updateBankingBank(e: any) {
    ThgCreateModule.setBanking({ bank: e.target.value });
  }

  //Rules and Form Validation

  valid = false;

  numberplateValid = false;

  setValidNumberplate(value: boolean) {
    this.numberplateValid = value;
  }

  get isDone() {
    return this.valid && this.numberplateValid && this.isProfileFormRequiredPopulated;
  }

  get numberPlateRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(numberplateRule());

    return rules;
  }

  get adressRule() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get noNumberRule() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(noNumberRule());

    return rules;
  }

  get zipRules() {
    const rules = [];

    rules.push(germanZipRule());

    return rules;
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());
    rules.push(noWhiteSpaceRule());
    rules.push(endsWithCharacterRule());

    return rules;
  }

  get ibanRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(ibanRule());

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    rules.push(phoneRule());

    return rules;
  }

  next() {
    GtagModule.dispatchAddShippingInfo();
  }

  /**
   * redirects to the next screen in the thg stepper
   */
  goNextScreen() {
    if (!this.valid) {
      return;
    }
    const next = ReportModule.nextScreen;
    ReportModule.setStep(next);
    this.$router.push({ path: `/${next}` });
  }
}
