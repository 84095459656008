





























































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThgMyVehicle } from "@/models/thg-my-vehicle.entity";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { RatingEntity } from "@/models/rating.entity";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { mixins } from "vue-class-component";
import { ReportModule } from "@/store/modules/report.store";
import { handleError } from "@/lib/utility/handleError";

/**
 * Enum for reasons to decommission a vehicle.
 */
export enum VehicleDecommissionReasonEnum {
  /**
   * The vehicle was sold.
   */
  VEHICLE_SOLD = "vehicle_sold",

  /**
   * The vehicle is damaged.
   */
  VEHICLE_DAMAGED = "vehicle_damaged",

  /**
   * The customer is unsatisfied with our service.
   */
  UNSATISFIED_WITH_SERVICE = "unsatisfied_with_service",

  /**
   * Other reasons.
   */
  OTHER = "other"
}

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class ThgMyVehicleDecommissionDialog extends mixins(DarkModeHighlightMixin, RulesMixin) {
  @Prop()
  vehicle!: IThgMyVehicle;

  @Prop()
  thgs!: ThgThgMeViewModelGen[];

  isDialogActive = false;

  decommissioningReason: VehicleDecommissionReasonEnum.VEHICLE_DAMAGED | null = null;

  decommissioningDate = "";

  validForm = false;
  isLoading = false;

  VehicleDecommissionReasonEnum = VehicleDecommissionReasonEnum;
  ratingEntity = new RatingEntity();

  get askForDate() {
    return [VehicleDecommissionReasonEnum.VEHICLE_SOLD, VehicleDecommissionReasonEnum.VEHICLE_DAMAGED].includes(
      this.ratingEntity.key as VehicleDecommissionReasonEnum
    );
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.isDialogActive = v;
  }

  get textAreaLabel() {
    if (this.ratingEntity.key === VehicleDecommissionReasonEnum.UNSATISFIED_WITH_SERVICE) {
      return this.$t("components.thg.ThgMyVehicleDecommissionDialog.feedbackLabelUnsatisfied").toString();
    } else if (this.ratingEntity.key === VehicleDecommissionReasonEnum.OTHER) {
      return this.$t("components.thg.ThgMyVehicleDecommissionDialog.feedbackLabelOther").toString();
    }
    return this.$t("components.thg.ThgMyVehicleDecommissionDialog.feedbackLabelDefault").toString();
  }

  close() {
    this.$emit("close");
    this.dialogModel = false;
  }

  open() {
    this.isDialogActive = true;
    this.ratingEntity = new RatingEntity();
  }

  async decommissionVehicle() {
    try {
      this.isLoading = true;
      if (this.decommissioningDate) {
        this.vehicle.decommissioningDate = new Date(this.decommissioningDate).toDateString();
      } else {
        this.vehicle.decommissioningDate = new Date().toDateString();
      }

      if (this.askForDate) {
        this.ratingEntity.rating = 5;
      } else {
        this.ratingEntity.rating = 1;
      }

      this.vehicle.isAutoRenewal = false;
      await this.vehicle.update();
      await this.ratingEntity.createRating(ReportModule.partner.id || ReportModule.partner._id);
      this.dialogModel = false;
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }
}
