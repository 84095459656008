import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IThgMyVehicle, ThgMyVehicle } from "@/models/thg-my-vehicle.entity";
import { IThgVehicle } from "@/models/thg-vehicle.entity";

export const ThgMyVehicleDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IThgVehicle> {
  protected mapEntity(entity: IThgMyVehicle): IThgMyVehicle {
    return new ThgMyVehicle(entity);
  }

  getIdentifier(entity: IThgMyVehicle): string {
    return entity.id;
  }
})();
