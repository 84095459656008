var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.thg.quickCheckoutEnabledFor && _vm.thg.quickCheckoutEnabledFor.length > 0 ? _c('v-btn', {
    attrs: {
      "color": _vm.color
    },
    on: {
      "click": _vm.startCheckout
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgQuickCheckout.btnText", {
    years: _vm.registrationYears
  })) + " ")]) : _vm._e(), _c('confirm-action-dialog', {
    attrs: {
      "outlined": false,
      "showGradient": true,
      "width": 600,
      "height": _vm.isMobile ? undefined : 750,
      "fullscreen": _vm.isMobile,
      "persistent": true,
      "isDialogActive": _vm.isDialogActive,
      "rightDisabled": _vm.isRightDisabled,
      "title": _vm.activeDialogConfig.title,
      "leftText": _vm.activeDialogConfig.leftText,
      "rightText": _vm.activeDialogConfig.rightText,
      "hideLeft": _vm.activeDialogConfig.hideLeft,
      "hideRight": _vm.activeDialogConfig.hideRight,
      "hideClose": _vm.activeDialogConfig.hideClose,
      "rightLoading": _vm.loading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": _vm.activeDialogConfig.leftClick,
      "rightClick": _vm.activeDialogConfig.rightClick
    }
  }, [_c('v-window', {
    model: {
      value: _vm.activeDialog,
      callback: function callback($$v) {
        _vm.activeDialog = $$v;
      },
      expression: "activeDialog"
    }
  }, [_c('v-window-item', {
    attrs: {
      "value": _vm.ThgDialogEnum.none
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.qaEngineer,
      "width": "100%",
      "contain": ""
    }
  }), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.$t("views.Error.subtitle")))])], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.ThgDialogEnum.thgDialogYearSelection
    }
  }, [_c('thg-dialog-year-selection', {
    attrs: {
      "numberplate": _vm.thg.numberplate,
      "possibleYears": (_vm.thg.quickCheckoutEnabledFor || []).map(function (f) {
        return f.year;
      })
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.ThgDialogEnum.thgDialogImageConfirm
    }
  }, [_c('thg-quick-checkout-validate-registration-card', {
    attrs: {
      "thg": _vm.thg
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.ThgDialogEnum.thgDialogImageUpload
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "px-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgQuickCheckout.thgDialogImageUpload.subtitle")) + " ")]), _c('thg-registration-upload-card')], 1)], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.ThgDialogEnum.thgDialogConfirm
    }
  }, [_c('thg-quick-checkout-closing-card', {
    attrs: {
      "isValid": _vm.isValid,
      "thg": _vm.thg
    },
    on: {
      "update:isValid": function updateIsValid($event) {
        _vm.isValid = $event;
      },
      "update:is-valid": function updateIsValid($event) {
        _vm.isValid = $event;
      }
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.ThgDialogEnum.thgDialogSuccess
    }
  }, [_c('thg-success-card', {
    on: {
      "close": _vm.close
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }