var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800",
      "fullscreen": _vm.$vuetify.breakpoint.xsOnly,
      "transition": "dialog-bottom-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_vm.btn ? _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "loading": _vm.isLoading,
            "color": "info"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgQuoteVehicleUpdateRegistrationDialog.dialogBtnText")) + " ")]) : _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "loading": _vm.isLoading,
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "grey lighten-1"
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgQuoteVehicleUpdateRegistrationDialog.dialogTitle")) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('components.thg.ThgQuoteVehicleUpdateRegistrationDialog.dialogText'))
    }
  }), _c('v-card-text', [_c('thg-quote-workitem-registration-card', {
    attrs: {
      "isResetValidation": true,
      "selectedThg": _vm.myThg,
      "selectedUser": _vm.user
    },
    on: {
      "submit": _vm.submit
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }