






















































































import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { ThgPriceHelper } from "@/lib/thg/thgPriceHelper";
import { getThgStatusOrDefault } from "@/lib/utility/thgStatusMap";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import ThgQuoteVehicleUpdateRegistrationDialog from "./ThgQuoteVehicleUpdateRegistrationDialog.vue";

@Component({
  components: {
    ThgQuoteVehicleUpdateRegistrationDialog
  }
})
export default class ThgMyVehicleThgQuoteDetail extends DarkModeHighlightMixin {
  @Prop()
  thg!: ThgThgMeViewModelGen;

  historyEnabled = false;
  priceEnabled = true;

  showInfos = false;
  showDetailInfos() {
    this.showInfos = !this.showInfos;
  }

  step = 1;

  get price() {
    return new ThgPriceHelper(this.thg);
  }

  get thgStatus(): IThgStatus {
    return getThgStatusOrDefault(this.thg.status);
  }
}
