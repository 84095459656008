



















import { IPriceConfig } from "@/store/models/thg/active-price";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ThgProductsPriceCard extends Vue {
  @Prop({ default: false })
  showPrice!: boolean;

  @Prop()
  product!: {
    years: number[];
    title: {
      token: string;
      values: {
        year: number;
      };
    };
    prices: [IPriceConfig];
  };

  get price() {
    return this.product.prices[0];
  }
}
