




















































































import ThgQuoteExportDialog from "@/components/thg/ThgQuoteExportDialog.vue";
import ThgQuoteUpdateMyRegistrationImageDialog from "@/components/thg/ThgQuoteUpdateMyRegistrationImageDialog.vue";
import ThgQuoteVehicleUpdateRegistrationDialog from "@/components/thg/ThgQuoteVehicleUpdateRegistrationDialog.vue";
import ThgVehicleTableLegendDialog from "@/components/thg/ThgVehicleTableLegendDialog.vue";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { IThgVehicleClass } from "@/lib/interfaces/thg/thg-vehicle-class.interface";
import { PartnerColor } from "@/lib/partnerColor";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import { thgVehicleClassMap } from "@/lib/utility/thgVehicleClassMap";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { Component, Prop } from "vue-property-decorator";
import ThgQuickCheckout from "./ThgQuickCheckout.vue";
import Tooltip from "../utility/tooltip.vue";
import ThgEmissionCalculationDialog from "./ThgEmissionCalculationDialog.vue";
import { requestEmissionCalculationData } from "@/lib/thg/atStatiDataRequest";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";

@Component({
  components: {
    Tooltip,
    ThgQuoteExportDialog,
    ThgQuoteVehicleUpdateRegistrationDialog,
    ThgQuoteUpdateMyRegistrationImageDialog,
    ThgVehicleTableLegendDialog,
    ThgQuickCheckout,
    ThgEmissionCalculationDialog
  }
})
export default class ThgVehicleTable extends DarkModeHighlightMixin {
  @Prop()
  myThgs!: ThgThgMeViewModelGen;

  thgStatusMap = thgStatusMap;
  thgVehicleClassMap = thgVehicleClassMap;
  thgStatusEnum = ThgStatusEnum;

  isLoading = false;

  search = "";

  partnerColor = new PartnerColor();

  requestEmissionCalculationData(thgStatusEnum: any): boolean {
    return requestEmissionCalculationData(thgStatusEnum);
  }

  thgStatus(thgStatusEnum: any): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum) || {
        text: "components.thg.ThgStatusMap.UNDEFINED.text",
        textAlt: "components.thg.ThgStatusMap.UNDEFINED.textAlt",
        icon: "mdi-help",
        portalText: "components.thg.ThgStatusMap.UNDEFINED.portalText",
        color: "error"
      }
    );
  }

  thgVehicleClass(thgVehicleClass: any): IThgVehicleClass | undefined {
    return this.thgVehicleClassMap.get(thgVehicleClass);
  }

  newCar() {
    this.$router.push({ path: "/thghome" });
  }

  get headers() {
    return [
      { text: this.$t("components.thg.ThgVehicleTable.table.status"), value: "status" },
      { text: this.$t("components.thg.ThgVehicleTable.table.year"), value: "year" },
      { text: this.$t("components.thg.ThgVehicleTable.table.numberplate"), value: "numberplate" },
      {
        text: this.$t("components.thg.ThgVehicleTable.table.vin"),
        value: "registration.identificationnumber"
      },
      { text: this.$t("components.thg.ThgVehicleTable.table.firstName"), value: "registration.firstname" },
      { text: this.$t("components.thg.ThgVehicleTable.table.lastName"), value: "registration.name" },
      { text: this.$t("components.thg.ThgVehicleTable.table.vehicleClass"), value: "registration.vehicleClass" },
      {
        text: "",
        value: "controls"
      }
    ];
  }

  get isQuickCheckoutEnabled() {
    return true;
  }

  isUpdateRegistrationImageActivated(item: IThg) {
    return (
      item.status == ThgStatusEnum.REQUEST_REGISTRATION_IMAGES ||
      item.status == ThgStatusEnum.REGISTRATION_IMAGES_REQUESTED
    );
  }

  refresh() {
    this.$emit("refresh");
  }
}
