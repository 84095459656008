































import { IPriceConfig } from "@/store/models/thg/active-price";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ThgProductsPriceComboCard extends Vue {
  @Prop({ default: false })
  showPrice!: boolean;

  @Prop()
  product!: {
    years: number[];
    title: {
      token: string;
      values: {
        year: number;
      };
    };
    prices: IPriceConfig[];
  };

  numberToWord(num: number) {
    const germanWords = [
      "common.numbers.none",
      "common.numbers.one",
      "common.numbers.two",
      "common.numbers.three",
      "common.numbers.four",
      "common.numbers.five"
    ];

    if (num >= 0 && num <= 5) {
      return this.$t(germanWords[num]);
    } else {
      return num.toString();
    }
  }
}
