import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { IThgStatus } from "../interfaces/thg/thg-status.interface";

export const thgStatusMap = new Map<ThgStatusEnum, IThgStatus>([
  [
    ThgStatusEnum.CREATED,
    {
      text: "components.thg.ThgStatusMap.CREATED.text",
      textAlt: "components.thg.ThgStatusMap.CREATED.textAlt",
      icon: "mdi-alert-decagram",
      portalText: "components.thg.ThgStatusMap.CREATED.portalText",
      color: "info"
    }
  ],
  [
    ThgStatusEnum.USER_ADDED_REGISTRATION,
    {
      text: "components.thg.ThgStatusMap.USER_ADDED_REGISTRATION.text",
      textAlt: "components.thg.ThgStatusMap.USER_ADDED_REGISTRATION.textAlt",
      icon: "mdi-alert-decagram",
      portalText: "components.thg.ThgStatusMap.USER_ADDED_REGISTRATION.portalText",
      color: "success"
    }
  ],
  [
    ThgStatusEnum.CONFIRMED,
    {
      text: "components.thg.ThgStatusMap.CONFIRMED.text",
      textAlt: "components.thg.ThgStatusMap.CONFIRMED.textAlt",
      icon: "mdi-check",
      portalText: "components.thg.ThgStatusMap.CONFIRMED.portalText",
      color: "success"
    }
  ],
  [
    ThgStatusEnum.SUBMITTED,
    {
      text: "components.thg.ThgStatusMap.SUBMITTED.text",
      textAlt: "components.thg.ThgStatusMap.SUBMITTED.textAlt",
      icon: "mdi-motion-pause-outline",
      portalText: "components.thg.ThgStatusMap.SUBMITTED.portalText",
      color: "success"
    }
  ],
  [
    ThgStatusEnum.WAIT_FOR_AGENCY,
    {
      text: "components.thg.ThgStatusMap.WAIT_FOR_AGENCY.text",
      textAlt: "components.thg.ThgStatusMap.WAIT_FOR_AGENCY.textAlt",
      icon: "mdi-motion-pause-outline",
      portalText: "components.thg.ThgStatusMap.WAIT_FOR_AGENCY.portalText",
      color: "success"
    }
  ],
  [
    ThgStatusEnum.CONFIRMED_BY_AGENCY,
    {
      text: "components.thg.ThgStatusMap.CONFIRMED_BY_AGENCY.text",
      textAlt: "components.thg.ThgStatusMap.CONFIRMED_BY_AGENCY.textAlt",
      icon: "mdi-battery-check-outline",
      portalText: "components.thg.ThgStatusMap.CONFIRMED_BY_AGENCY.portalText",
      color: "success"
    }
  ],
  [
    ThgStatusEnum.REQUEST_DATA,
    {
      text: "components.thg.ThgStatusMap.REQUEST_DATA.text",
      textAlt: "components.thg.ThgStatusMap.REQUEST_DATA.textAlt",
      icon: "mdi-autorenew",
      portalText: "components.thg.ThgStatusMap.REQUEST_DATA.portalText",
      color: "warning"
    }
  ],
  [
    ThgStatusEnum.DATA_REQUESTED,
    {
      text: "components.thg.ThgStatusMap.DATA_REQUESTED.text",
      textAlt: "components.thg.ThgStatusMap.DATA_REQUESTED.textAlt",
      icon: "mdi-autorenew",
      portalText: "components.thg.ThgStatusMap.DATA_REQUESTED.portalText",
      color: "warning"
    }
  ],
  [
    ThgStatusEnum.REJECTED_BY_AGENCY,
    {
      text: "components.thg.ThgStatusMap.REJECTED_BY_AGENCY.text",
      textAlt: "components.thg.ThgStatusMap.REJECTED_BY_AGENCY.textAlt",
      icon: "mdi-close-outline",
      portalText: "components.thg.ThgStatusMap.REJECTED_BY_AGENCY.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.HYBRID_AT,
    {
      text: "components.thg.ThgStatusMap.HYBRID_AT.text",
      textAlt: "components.thg.ThgStatusMap.HYBRID_AT.textAlt",
      icon: "mdi-close-outline",
      portalText: "components.thg.ThgStatusMap.HYBRID_AT.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.PAYED,
    {
      text: "components.thg.ThgStatusMap.PAYED.text",
      textAlt: "components.thg.ThgStatusMap.PAYED.textAlt",
      icon: "mdi-currency-usd",
      portalText: "components.thg.ThgStatusMap.PAYED.portalText",
      color: "success"
    }
  ],
  [
    ThgStatusEnum.DUPLICATE,
    {
      text: "components.thg.ThgStatusMap.DUPLICATE.text",
      textAlt: "components.thg.ThgStatusMap.DUPLICATE.textAlt",
      icon: "mdi-numeric-2-box-multiple-outline",
      portalText: "components.thg.ThgStatusMap.DUPLICATE.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.CANCELED,
    {
      text: "components.thg.ThgStatusMap.CANCELED.text",
      textAlt: "components.thg.ThgStatusMap.CANCELED.textAlt",
      icon: "mdi-close-box-outline",
      portalText: "components.thg.ThgStatusMap.CANCELED.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.DELETED,
    {
      text: "components.thg.ThgStatusMap.DELETED.text",
      textAlt: "components.thg.ThgStatusMap.DELETED.textAlt",
      icon: "mdi-close-box-outline",
      portalText: "components.thg.ThgStatusMap.DELETED.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.NO_BEV,
    {
      text: "components.thg.ThgStatusMap.NO_BEV.text",
      textAlt: "components.thg.ThgStatusMap.NO_BEV.textAlt",
      icon: "mdi-engine-outline",
      portalText: "components.thg.ThgStatusMap.NO_BEV.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.SPAM,
    {
      text: "components.thg.ThgStatusMap.SPAM.text",
      textAlt: "components.thg.ThgStatusMap.SPAM.textAlt",
      icon: "mdi-close-box-outline",
      portalText: "components.thg.ThgStatusMap.SPAM.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.TEST,
    {
      text: "components.thg.ThgStatusMap.TEST.text",
      textAlt: "components.thg.ThgStatusMap.TEST.textAlt",
      icon: "mdi-close-box-outline",
      portalText: "components.thg.ThgStatusMap.TEST.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.REQUEST_REGISTRATION_IMAGES,
    {
      text: "components.thg.ThgStatusMap.REQUEST_REGISTRATION_IMAGES.text",
      textAlt: "components.thg.ThgStatusMap.REQUEST_REGISTRATION_IMAGES.textAlt",
      icon: "mdi-autorenew",
      portalText: "components.thg.ThgStatusMap.REQUEST_REGISTRATION_IMAGES.portalText",
      color: "warning"
    }
  ],
  [
    ThgStatusEnum.REGISTRATION_IMAGES_REQUESTED,
    {
      text: "components.thg.ThgStatusMap.REGISTRATION_IMAGES_REQUESTED.text",
      textAlt: "components.thg.ThgStatusMap.REGISTRATION_IMAGES_REQUESTED.textAlt",
      icon: "mdi-autorenew",
      portalText: "components.thg.ThgStatusMap.REGISTRATION_IMAGES_REQUESTED.portalText",
      color: "warning"
    }
  ],
  [
    ThgStatusEnum.NO_GERMAN_REGISTRATION_DOCUMENT,
    {
      text: "components.thg.ThgStatusMap.NO_GERMAN_REGISTRATION_DOCUMENT.text",
      textAlt: "components.thg.ThgStatusMap.NO_GERMAN_REGISTRATION_DOCUMENT.textAlt",
      icon: "mdi-close-box-outline",
      portalText: "components.thg.ThgStatusMap.NO_GERMAN_REGISTRATION_DOCUMENT.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.NO_PUBLIC_ACCESS,
    {
      text: "components.thg.ThgStatusMap.NO_PUBLIC_ACCESS.text",
      textAlt: "components.thg.ThgStatusMap.NO_PUBLIC_ACCESS.textAlt",
      icon: "mdi-close-box-outline",
      portalText: "components.thg.ThgStatusMap.NO_PUBLIC_ACCESS.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.METER_READING_CHANGE_REQUESTED,
    {
      text: "components.thg.ThgStatusMap.METER_READING_CHANGE_REQUESTED.text",
      textAlt: "components.thg.ThgStatusMap.METER_READING_CHANGE_REQUESTED.textAlt",
      icon: "mdi-close-box-outline",
      portalText: "components.thg.ThgStatusMap.METER_READING_CHANGE_REQUESTED.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.IMPLAUSIBLE,
    {
      text: "components.thg.ThgStatusMap.IMPLAUSIBLE.text",
      textAlt: "components.thg.ThgStatusMap.IMPLAUSIBLE.textAlt",
      icon: "mdi-close-box-outline",
      portalText: "components.thg.ThgStatusMap.IMPLAUSIBLE.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.INVALID_VEHICLE_CLASS,
    {
      text: "components.thg.ThgStatusMap.INVALID_VEHICLE_CLASS.text",
      textAlt: "components.thg.ThgStatusMap.INVALID_VEHICLE_CLASS.textAlt",
      icon: "mdi-close-box-outline",
      portalText: "components.thg.ThgStatusMap.INVALID_VEHICLE_CLASS.portalText",
      color: "error"
    }
  ],
  [
    ThgStatusEnum.PAYED_AND_INVALID_VEHICLE_CLASS,
    {
      text: "components.thg.ThgStatusMap.PAYED_AND_INVALID_VEHICLE_CLASS.text",
      textAlt: "components.thg.ThgStatusMap.PAYED_AND_INVALID_VEHICLE_CLASS.textAlt",
      icon: "mdi-currency-usd",
      portalText: "components.thg.ThgStatusMap.PAYED_AND_INVALID_VEHICLE_CLASS.portalText",
      color: "success"
    }
  ],
  [
    ThgStatusEnum.DATA_REQUESTED_BEV_AT_QUOTA_DETAILS,
    {
      text: "components.thg.ThgStatusMap.DATA_REQUESTED_BEV_AT_QUOTA_DETAILS.text",
      textAlt: "components.thg.ThgStatusMap.DATA_REQUESTED_BEV_AT_QUOTA_DETAILS.textAlt",
      icon: "mdi-autorenew",
      portalText: "components.thg.ThgStatusMap.DATA_REQUESTED_BEV_AT_QUOTA_DETAILS.portalText",
      color: "warning"
    }
  ],
  [
    ThgStatusEnum.DATA_REQUESTED_HYBRID_AT_QUOTA_DETAILS,
    {
      text: "components.thg.ThgStatusMap.DATA_REQUESTED_HYBRID_AT_QUOTA_DETAILS.text",
      textAlt: "components.thg.ThgStatusMap.DATA_REQUESTED_HYBRID_AT_QUOTA_DETAILS.textAlt",
      icon: "mdi-autorenew",
      portalText: "components.thg.ThgStatusMap.DATA_REQUESTED_HYBRID_AT_QUOTA_DETAILS.portalText",
      color: "warning"
    }
  ],
  [
    ThgStatusEnum.DATA_UPDATED_BEV_AT_QUOTA_DETAILS,
    {
      text: "components.thg.ThgStatusMap.DATA_UPDATED_BEV_AT_QUOTA_DETAILS.text",
      textAlt: "components.thg.ThgStatusMap.DATA_UPDATED_BEV_AT_QUOTA_DETAILS.textAlt",
      icon: "mdi-check",
      portalText: "components.thg.ThgStatusMap.DATA_UPDATED_BEV_AT_QUOTA_DETAILS.portalText",
      color: "success"
    }
  ],
  [
    ThgStatusEnum.DATA_UPDATED_HYBRID_AT_QUOTA_DETAILS,
    {
      text: "components.thg.ThgStatusMap.DATA_UPDATED_HYBRID_AT_QUOTA_DETAILS.text",
      textAlt: "components.thg.ThgStatusMap.DATA_UPDATED_HYBRID_AT_QUOTA_DETAILS.textAlt",
      icon: "mdi-check",
      portalText: "components.thg.ThgStatusMap.DATA_UPDATED_HYBRID_AT_QUOTA_DETAILS.portalText",
      color: "success"
    }
  ],
  [
    ThgStatusEnum.NO_CHARGING_DETAILS_HYBRID_AT_QUOTA_DETAILS,
    {
      text: "components.thg.ThgStatusMap.NO_CHARGING_DETAILS_HYBRID_AT_QUOTA_DETAILS.text",
      textAlt: "components.thg.ThgStatusMap.NO_CHARGING_DETAILS_HYBRID_AT_QUOTA_DETAILS.textAlt",
      icon: "mdi-close-box-outline",
      portalText: "components.thg.ThgStatusMap.NO_CHARGING_DETAILS_HYBRID_AT_QUOTA_DETAILS.portalText",
      color: "error"
    }
  ]
]);

export function getThgStatusOrDefault(status: ThgStatusEnum | undefined | string): IThgStatus {
  const defaultStatus = {
    text: "components.thg.ThgStatusMap.UNDEFINED.text",
    textAlt: "components.thg.ThgStatusMap.UNDEFINED.textAlt",
    icon: "mdi-help",
    portalText: "components.thg.ThgStatusMap.UNDEFINED.portalText",
    color: "error"
  };
  if (!status) {
    return defaultStatus;
  }

  return thgStatusMap.get(status as ThgStatusEnum) || defaultStatus;
}
