import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IThgVehicle, ThgVehicle } from "@/models/thg-vehicle.entity";
import thgVehicleService from "@/services/thg/services/thgVehicleService";
import {
  ThgThgVehicleControllerGetAllPaginatedParamsGen,
  ThgUpdateVehicleDtoGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ThgVehicleDataAccessLayer } from "./access-layers/thg-vehicle.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";

const ThgVehiclePageDataProvider = new (class extends AbstractPageDataProvider<
  IThgVehicle,
  ThgThgVehicleControllerGetAllPaginatedParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: ThgThgVehicleControllerGetAllPaginatedParamsGen): Promise<IPageViewModel<IThgVehicle>> {
    const res = await thgVehicleService.findAll(query);

    const meta = res.meta;
    const data = (res.data ?? []) as IThgVehicle[];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "thg-vehicle",
  store
})
export class ThgVehiclePaginationStore extends PaginatedBaseStore<
  IThgVehicle,
  ThgThgVehicleControllerGetAllPaginatedParamsGen
> {
  protected _data = ThgVehicleDataAccessLayer;
  protected _pageProvider = ThgVehiclePageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  private _thgVehicle: IThgVehicle | undefined = undefined;

  get thgVehicle() {
    return this._thgVehicle;
  }

  @Mutation
  mutateVehicle(vehicle: IThgVehicle) {
    this._thgVehicle = vehicle;
  }

  @Action
  setVehicle(vehicle: IThgVehicle | undefined) {
    this.context.commit("mutateVehicle", vehicle);
  }

  @Action
  async getOne(id: string) {
    const res = await thgVehicleService.findOne(id);

    const vehicle = new ThgVehicle(res);
    this._data.set(vehicle);

    this.context.commit("mutateVehicle", vehicle);

    return vehicle;
  }

  @Action
  async updateOne(data: { id: string; dto: ThgUpdateVehicleDtoGen }) {
    const res = await thgVehicleService.updateOne(data.id, data.dto);

    const vehicle = new ThgVehicle(res);
    this._data.set(vehicle);

    this.context.commit("mutateVehicle", vehicle);

    return vehicle;
  }

  filterOptions: PaginationFilterListElement[] = ThgVehicle?.filterables || [];
}

export const ThgVehicleModule = getModule(ThgVehiclePaginationStore);
