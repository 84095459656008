var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    attrs: {
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(_vm.product.title.token, _vm.product.title.values)) + " "), _c('v-chip', {
    attrs: {
      "color": "success",
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("report.thgProducts.popular")) + " ")])], 1), _c('v-list-item-subtitle', {
    domProps: {
      "innerHTML": _vm._s(_vm.product.years.join(' & '))
    }
  }), _vm._l(_vm.product.prices, function (price, idx) {
    return _c('div', {
      key: idx,
      class: idx !== _vm.product.prices.length - 1 ? 'mb-2' : ''
    }, [price.subtitle ? _c('v-list-item-subtitle', {
      domProps: {
        "innerHTML": _vm._s(price.subtitle)
      }
    }) : _vm._e(), price.description ? _c('v-list-item-subtitle', {
      domProps: {
        "innerHTML": _vm._s(price.description)
      }
    }) : _vm._e(), price.hint ? _c('v-list-item-subtitle', {
      domProps: {
        "innerHTML": _vm._s(price.hint)
      }
    }) : _vm._e(), _vm.showPrice ? _c('v-list-item-subtitle', [_c('b', [_vm._v(" " + _vm._s(_vm.$t(price.prefix) + " " + price.totalAmountAsString))])]) : _vm._e()], 1);
  })], 2), _c('v-list-item-action', [_c('v-radio', {
    attrs: {
      "value": _vm.product.years
    }
  })], 1)], 1), _c('div', {
    staticClass: "text-caption mb-4 mx-4 d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check-decagram")]), _vm._v(" " + _vm._s(_vm.$t("report.thgProducts.multipleYear", {
    amount: _vm.numberToWord(_vm.product.prices.length)
  })) + " ")], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }