/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * THG Vehicle State Enum
 */
export enum ThgVehicleStateEnum {
  CREATED = "created",
  CONFIRMED = "confirmed",
  DELETED = "deleted",
  DUPLICATE = "duplicate",
  INVALID_VEHICLE_CLASS = "invalid_vehicle_class",
  VIN_MISS_MATCH = "vin_miss_match"
}
