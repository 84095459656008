














import { Component, Vue, Prop } from "vue-property-decorator";
import ImageDialog from "../utility/ImageDialog.vue";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";
import { ThgCreateModule } from "@/store/modules/thg.create.store";

@Component({
  components: {
    ImageDialog
  }
})
export default class ThgQuickCheckoutValidateRegistrationCard extends Vue {
  @Prop()
  thg!: ThgThgMeViewModelGen;

  get isRegistrationValid() {
    return ThgCreateModule.isRegistrationValid;
  }

  set isRegistrationValid(isRegistrationValid: boolean) {
    ThgCreateModule.setIsRegistrationValid(isRegistrationValid);
  }

  get frontImage() {
    if (this.thg.registrationImages.length && this.thg.registrationImages[0].url) {
      return this.thg.registrationImages[0].url;
    }
    return undefined;
  }

  get backImage() {
    if (this.thg.registrationImages.length > 1 && this.thg.registrationImages[1].url) {
      return this.thg.registrationImages[1].url;
    }
    return undefined;
  }
}
