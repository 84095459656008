


















import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThgMyVehicle } from "@/models/thg-my-vehicle.entity";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { Component, Prop } from "vue-property-decorator";
import ThgQuoteVehicleUpdateRegistrationDialog from "./ThgQuoteVehicleUpdateRegistrationDialog.vue";

@Component({
  components: {
    ThgQuoteVehicleUpdateRegistrationDialog
  }
})
export default class ThgMyVehicleRegistrationDetail extends DarkModeHighlightMixin {
  @Prop()
  vehicle!: IThgMyVehicle;

  @Prop()
  thgs!: ThgThgMeViewModelGen[];

  get showUpdateRegistrationDialog(): ThgThgMeViewModelGen | undefined {
    for (const thg of this.thgs) {
      if (thg.status === ThgStatusEnum.CREATED) {
        return thg;
      }
    }

    return undefined;
  }
}
