





































































































































import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { PartnerColor } from "@/lib/partnerColor";
import { handleError } from "@/lib/utility/handleError";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import {
  ThgChargingStationProofDocumentDtoGen,
  ThgChargingStationViewModelGen,
  ThgThgMeterReadingViewModelGen,
  ThgUpdateMyChargingStationShortDtoGen
} from "@/services/thg/v1/data-contracts";
import { ChargingStationModule } from "@/store/modules/charging-station.store";
import { FeatureModule } from "@/store/modules/feature.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import ThgChargingStationHelpDialog from "./ThgChargingStationHelpDialog.vue";
import ThgChargingStationMeterReadingTable from "./ThgChargingStationMeterReadingTable.vue";
import ThgChargingStationProofChipList from "./ThgChargingStationProofChipList.vue";

@Component({
  components: {
    Debug,
    ThgChargingStationMeterReadingTable,
    ThgChargingStationProofChipList,
    ThgChargingStationHelpDialog
  }
})
export default class ThgChargingStationCard extends Vue {
  @Prop()
  selection!: ThgChargingStationViewModelGen;

  @Prop()
  meterReadings!: ThgThgMeterReadingViewModelGen[];

  @Prop()
  loading!: boolean;

  partnerColor = new PartnerColor();
  thgStatusMap = thgStatusMap;

  get isBnetzA() {
    return this.selection.address.countryCode === "DE";
  }

  get isRenewableChargingStationEnabled() {
    return FeatureModule.isRenewableChargingStationEnabled;
  }

  get isFromRenewableEnergy() {
    return this.selection.isFromRenewableEnergy
      ? this.$t("components.thg.ThgChargingStationCard.yes")
      : this.$t("components.thg.ThgChargingStationCard.no");
  }

  get charging() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.charging);
  }

  get dateOptions() {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    return options;
  }

  get commissioningDate() {
    return this.toDate(this.selection.commissioningDate);
  }

  get proofs() {
    return this.selection.proofs;
  }

  get registrationDate(): string {
    if (!this.selection.registrationDate) {
      return "";
    }

    return this.toDate(this.selection.registrationDate);
  }

  get isEvseEnabled() {
    return FeatureModule.isEvseEnabled;
  }

  getEvseId(powerOutletId: string) {
    if (!this.selection.chargingPointOperator) {
      return "";
    }

    return `${this.selection.address.countryCode}*${this.selection.chargingPointOperator.evseOperatorId}*E${powerOutletId}`;
  }

  async onProofUpdate(files: ThgChargingStationProofDocumentDtoGen[]) {
    const refIds = this.selection?.refs?.map(ref => ref.refId) || [];
    /** get ids of newly added files */
    const newFiles = files.filter(file => !refIds.includes(file.id));

    /** Don't update charging station if there are no uploaded proofs(new ones) */
    if (newFiles.length === 0) {
      return;
    }

    const data: ThgUpdateMyChargingStationShortDtoGen = {
      refs: []
    };

    if (!data.refs) {
      return;
    }

    if (this.selection.refs) {
      data.refs.push(...this.selection.refs);
    }

    data.refs.push(
      ...newFiles.map(file => ({
        refId: file.id,
        refType: file.extensionType
      }))
    );

    try {
      await ChargingStationModule.updateChargingStation({
        id: this.selection.id,
        data
      });
      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    }
  }

  addMeterReading() {
    this.$router.push({ path: `/charging-station/${this.selection.id}/meter-reading` });
  }

  toDate(date: string) {
    return new Date(date).toLocaleString("de-DE", this.dateOptions as any);
  }

  thgStatus(thgStatusEnum: any): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum) || {
        text: "components.thg.ThgStatusMap.UNDEFINED.text",
        textAlt: "components.thg.ThgStatusMap.UNDEFINED.textAlt",
        icon: "mdi-help",
        portalText: "components.thg.ThgStatusMap.UNDEFINED.portalText",
        color: "error"
      }
    );
  }

  goToChargingStation(item: ThgChargingStationViewModelGen) {
    this.$router.push({ path: `/charging-station/${item.id}` });
  }

  hasGeo(selection: ThgChargingStationViewModelGen) {
    if (!selection.address.geo) {
      return false;
    }

    if (selection.address.geo.lat <= 0 && selection.address.geo.lng <= 0) {
      return false;
    }

    return true;
  }
}
