import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IThgMyVehicle, ThgMyVehicle } from "@/models/thg-my-vehicle.entity";
import thgVehicleMeService from "@/services/thg/services/thgVehicleMeService";
import {
  ThgThgVehicleMeControllerGetAllPaginatedParamsGen,
  ThgUpdateMyVehicleDtoGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ThgMyVehicleDataAccessLayer } from "./access-layers/thg-my-vehicle.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";

const ThgMyVehiclePageDataProvider = new (class extends AbstractPageDataProvider<
  IThgMyVehicle,
  ThgThgVehicleMeControllerGetAllPaginatedParamsGen
> {
  itemsPerPage = 1500;

  async getPage(query: ThgThgVehicleMeControllerGetAllPaginatedParamsGen): Promise<IPageViewModel<IThgMyVehicle>> {
    const res = await thgVehicleMeService.findAll(query);

    const meta = res.meta;
    const data = (res.data ?? []) as IThgMyVehicle[];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "thg-me-vehicle",
  store
})
export class ThgMyVehiclePaginationStore extends PaginatedBaseStore<
  IThgMyVehicle,
  ThgThgVehicleMeControllerGetAllPaginatedParamsGen
> {
  protected _data = ThgMyVehicleDataAccessLayer;
  protected _pageProvider = ThgMyVehiclePageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  private _thgVehicle: IThgMyVehicle | undefined = undefined;

  get thgVehicle() {
    return this._thgVehicle;
  }

  @Mutation
  mutateVehicle(vehicle: IThgMyVehicle) {
    this._thgVehicle = vehicle;
  }

  @Action
  setVehicle(vehicle: IThgMyVehicle | undefined) {
    this.context.commit("mutateVehicle", vehicle);
  }

  @Action
  async getOne(id: string) {
    const res = await thgVehicleMeService.findOne(id);

    const vehicle = new ThgMyVehicle(res);
    this._data.set(vehicle);

    this.context.commit("mutateVehicle", vehicle);

    return vehicle;
  }

  @Action
  async updateOne(data: { id: string; dto: ThgUpdateMyVehicleDtoGen }) {
    const res = await thgVehicleMeService.updateOne(data.id, data.dto);

    const vehicle = new ThgMyVehicle(res);
    this._data.set(vehicle);

    this.context.commit("mutateVehicle", vehicle);

    return vehicle;
  }

  filterOptions: PaginationFilterListElement[] = ThgMyVehicle.filterables;
}

export const ThgMyVehicleModule = getModule(ThgMyVehiclePaginationStore);
