/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ThgHttpClientProvider } from "../thg-http-client.provider";
import { Vehicles } from "../v1/Vehicles";
import {
  ThgPageViewModelGen,
  ThgThgVehicleControllerGetAllPaginatedParamsGen,
  ThgThgVehicleViewModelGen,
  ThgUpdateVehicleDtoGen
} from "../v1/data-contracts";

/**
 * Service for interacting with the thg vehicle endpoint.
 */
export class ThgVehicleService {
  private readonly vehicles: Vehicles;

  constructor(clientProvider: ThgHttpClientProvider) {
    this.vehicles = new Vehicles(clientProvider.client());
  }

  /**
   * Retrieves all thg vehicle records
   *
   * @param query - Parameters for retrieving thg vehicle data.
   * @returns A paginated view model containing thg vehicle data.
   */
  async findAll(
    query: ThgThgVehicleControllerGetAllPaginatedParamsGen
  ): Promise<ThgPageViewModelGen & { data?: ThgThgVehicleViewModelGen[] | undefined }> {
    return (await this.vehicles.thgVehicleControllerGetAllPaginated(query)).data;
  }

  /**
   * Retrieves all thg vehicle records
   *
   * @param query - Parameters for retrieving thg vehicle data.
   * @returns A paginated view model containing thg vehicle data.
   */
  async findOne(id: string): Promise<ThgThgVehicleViewModelGen> {
    return (await this.vehicles.thgVehicleControllerGetOne(id)).data;
  }

  /**
   * Retrieves all thg vehicle records
   *
   * @param query - Parameters for retrieving thg vehicle data.
   * @returns A paginated view model containing thg vehicle data.
   */
  async updateOne(id: string, dto: ThgUpdateVehicleDtoGen): Promise<ThgThgVehicleViewModelGen> {
    return (await this.vehicles.thgVehicleControllerUpdateOne(id, dto)).data;
  }
}

export default new ThgVehicleService(new ThgHttpClientProvider());
