var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10 mx-2"
  }, [_c('v-btn-toggle', {
    attrs: {
      "mandatory": "",
      "borderless": "",
      "color": _vm.partnerColor
    },
    model: {
      value: _vm.filter.isDeregistered,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "isDeregistered", $$v);
      },
      expression: "filter.isDeregistered"
    }
  }, [_c('v-btn', {
    attrs: {
      "value": false
    }
  }, [_c('span', {
    staticClass: "hidden-sm-and-down"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicles.activeVehicles")) + " ")]), _c('v-icon', {
    staticClass: "mx-2",
    attrs: {
      "color": _vm.activeVehicleFilterColor
    }
  }, [_vm._v(" mdi-car ")])], 1), _c('v-btn', {
    attrs: {
      "value": true
    }
  }, [_c('span', {
    staticClass: "hidden-sm-and-down"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicles.deregisteredVehicles")) + " ")]), _c('v-icon', {
    staticClass: "mx-2",
    attrs: {
      "color": _vm.decommissionedVehicleFilterColor
    }
  }, [_vm._v(" mdi-car-off ")])], 1)], 1), _c('div', {
    staticClass: "my-8 text-subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicles.vehiclesFound", {
    count: _vm.groupedThgs.length
  })) + " ")]), !_vm.isLoading ? _c('div', _vm._l(_vm.groupedThgs, function (vehicle) {
    return _c('div', {
      key: vehicle
    }, [_c('thg-my-vehicle', {
      attrs: {
        "vehicle": vehicle.vehicle,
        "thgs": vehicle.thgs
      }
    })], 1);
  }), 0) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }