import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { ThgAutoRenewalHistoryElementViewModelGen } from "@/services/thg/v1/data-contracts";
import { ITimestamp, Timestamp } from "./timestamp.entity";

@IsFilterable
class IsAutoRenewalHistoryElementBase implements ThgAutoRenewalHistoryElementViewModelGen {
  @FilterConfig({ displayName: "objects.churn.user.processed", type: FilterTypes.BOOLEAN })
  value: boolean;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.thgVehicle.userId"
  })
  userId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  constructor(data?: Partial<IsAutoRenewalHistoryElementBase | ThgAutoRenewalHistoryElementViewModelGen>) {
    this.value = data?.value || false;
    this.userId = data?.userId || "";
    this.timestamp = new Timestamp(data?.timestamp);
  }
}

type IIsAutoRenewalHistoryElementBase = IsAutoRenewalHistoryElementBase;
const IsAutoRenewalHistoryElement = Filter.createForClass(IsAutoRenewalHistoryElementBase);

export { IIsAutoRenewalHistoryElementBase, IsAutoRenewalHistoryElement };
