

































































































































































import ThgQuoteUpdateMyRegistrationImageDialog from "@/components/thg/ThgQuoteUpdateMyRegistrationImageDialog.vue";
import ThgQuoteVehicleUpdateRegistrationDialog from "@/components/thg/ThgQuoteVehicleUpdateRegistrationDialog.vue";
import ImageDialog from "@/components/utility/ImageDialog.vue";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { simpleDate } from "@/lib/utility/date-helper";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { Component, Prop, Vue } from "vue-property-decorator";
import ThgQuickCheckout from "./ThgQuickCheckout.vue";
import ThgEmissionCalculationDialog from "./ThgEmissionCalculationDialog.vue";
import { requestEmissionCalculationData } from "@/lib/thg/atStatiDataRequest";
import { ThgBatchDetailViewmodelGen } from "@/services/thg/v1/data-contracts";

@Component({
  components: {
    ThgQuoteVehicleUpdateRegistrationDialog,
    ThgQuoteUpdateMyRegistrationImageDialog,
    ImageDialog,
    ThgQuickCheckout,
    ThgEmissionCalculationDialog
  }
})
export default class ThgVehicleCard extends Vue {
  @Prop()
  myThg!: ThgThgMeViewModelGen;

  @Prop({})
  batch?: ThgBatchDetailViewmodelGen;

  thgStatusMap = thgStatusMap;

  requestEmissionCalculationData(thgStatusEnum: any): boolean {
    return requestEmissionCalculationData(thgStatusEnum);
  }

  thgStatus(thgStatusEnum: any): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum) || {
        text: "components.thg.ThgStatusMap.UNDEFINED.text",
        textAlt: "components.thg.ThgStatusMap.UNDEFINED.textAlt",
        icon: "mdi-help",
        portalText: "components.thg.ThgStatusMap.UNDEFINED.portalText",
        color: "error"
      }
    );
  }

  get promotionValue() {
    return this.myThg?.promotionConfiguration?.value?.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });
  }

  get showRegistrationUpdateDialog() {
    return [ThgStatusEnum.CREATED].includes(this.myThg.status as ThgStatusEnum);
  }

  get showRegistrationImageUpdateDialog() {
    if ([ThgStatusEnum.REQUEST_REGISTRATION_IMAGES].includes(this.myThg.status as any)) {
      return true;
    } else {
      return false;
    }
  }

  get cashImpactInvestment() {
    return this.myThg.impactFactor;
  }

  get fullname() {
    let divider = "";
    if (this.myThg.registration.firstname) {
      divider = " ";
    }
    return ((this.myThg.registration.firstname as any) + divider + this.myThg.registration.name) as any;
  }

  get customerAccount() {
    return this.myThg.impactType === (ImpactTypeEnum.customerAccount as any);
  }

  get firstRegistrationDate() {
    let divider = "";
    if (this.myThg.registration.firstregistrationDay) {
      divider = ".";
    }
    return (
      (((this.myThg.registration.firstregistrationDay as any) +
        divider +
        this.myThg.registration.firstregistrationMonth) as any) +
      divider +
      this.myThg.registration.firstregistrationYear
    );
  }

  get cashPayment() {
    return 100 - this.myThg.impactFactor;
  }

  get registrationImageFront(): string {
    const item = this.myThg.registrationImages.filter(item => item.type === (ReportImageType.registration as any));
    if (item.length !== 0) {
      return item[0].url || "";
    }
    return "";
  }

  get dateSimple() {
    const timestamp = this.myThg?.timestamp as any;

    if (timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return simpleDate(timestamp?.created, locale);
    }
    return "";
  }

  get decommissionedDateSimple() {
    if (this.myThg?.decommissioningDate) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return simpleDate(this.myThg?.decommissioningDate, locale);
    }
    return "";
  }

  get registrationImageBack(): string {
    const item = this.myThg.registrationImages.filter(item => item.type === (ReportImageType.registrationBack as any));
    if (item.length !== 0) {
      return item[0].url || "";
    }
    return "";
  }

  get isQuickCheckoutEnabled() {
    return true;
  }

  thgImpactTyeItemByEnum(impactType: any) {
    return ThgCreateModule.impactTypeItems.filter(item => item.impactType === impactType)[0];
  }

  thgImpactImage(impactType: any) {
    return this.thgImpactTyeItemByEnum(impactType);
  }

  openRegistrationImage(imageLink: string) {
    window.open(imageLink, "_blank");
  }

  refresh() {
    this.$emit("refresh");
  }
}
