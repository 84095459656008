























































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ThgIsCompanyRequired extends ReportStepMixin implements IReportStep {
  md = 4;
  header: IReportHeaderData = {
    title: "report.thgIsCompanyRequired.title",
    description: "report.thgIsCompanyRequired.description"
  };

  screen = ReportScreenEnum.thgIsCompanyRequired;

  switchToWkthg() {
    window.open("https://app.wirkaufendeinethg.de/#/", "_blank");
  }

  get isCompany() {
    return ThgCreateModule.isCompany;
  }

  set isCompany(isCompany: boolean) {
    ThgCreateModule.setIsCompany(isCompany);
  }

  get isDone() {
    return !!this.isCompany;
  }

  get partnerId() {
    return ReportModule.partner.id || ReportModule.partner._id;
  }

  /**
   * Validate zip if it is already set e.g. from store
   */
  async mounted() {
    this.isCompany = true;
    this.setCurrentStep();
  }
}
