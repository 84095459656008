var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.vehicle.decommissioningDate ? _c('v-sheet', {
    staticClass: "mx-4 mb-8 mt-6 red lighten-3",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "grow"
  }, [_c('div', {
    staticClass: "ma-4"
  }, [_c('div', {
    staticClass: "font-weight-black"
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "error"
    }
  }, [_vm._v("mdi-logout")]), _vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicleRenewalOptionsSheet.vehicleDeregistered", {
    decommissioningDateReadable: _vm.vehicle.decommissioningDateReadable
  })) + " ")], 1), _c('div', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicleRenewalOptionsSheet.vehicleDeregisteredCaption")) + " ")])])]), _c('v-col', {
    staticClass: "shrink"
  }, [_c('v-btn', {
    staticClass: "ma-4",
    attrs: {
      "color": "error",
      "loading": _vm.isLoading
    },
    on: {
      "click": _vm.reDecommission
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicleRenewalOptionsSheet.activateVehicleButton")) + " ")])], 1)], 1)], 1) : _vm.vehicle.isAutoRenewal ? _c('v-sheet', {
    staticClass: "mx-4 mb-8 mt-6 light-green accent-1",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "grow"
  }, [_c('div', {
    staticClass: "mx-4"
  }, [_c('div', {
    staticClass: "font-weight-black"
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "success"
    }
  }, [_vm._v("mdi-autorenew")]), _vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicleRenewalOptionsSheet.autoRenewalActivated")) + " ")], 1), _c('div', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicleRenewalOptionsSheet.autoRenewalCaption")) + " ")])])]), _c('v-col', {
    staticClass: "shrink"
  }, [_c('v-btn', {
    staticClass: "ma-4",
    attrs: {
      "color": "success",
      "loading": _vm.isLoading
    },
    on: {
      "click": function click($event) {
        return _vm.setAutoRenewal(false);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicleRenewalOptionsSheet.deactivateAutoRenewalButton")) + " ")])], 1)], 1)], 1) : _vm.latestQuickCheckoutOption ? _c('v-sheet', {
    staticClass: "mx-4 mb-6 mt-4 border-light-green",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "grow"
  }, [_c('div', {
    staticClass: "ma-4"
  }, [_c('div', {
    staticClass: "font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicleRenewalOptionsSheet.applyThgPremium")) + " ")]), _c('div', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicleRenewalOptionsSheet.applyThgPremiumCaption")) + " ")])])]), _c('v-col', {
    staticClass: "shrink"
  }, [_c('thg-quick-checkout', {
    staticClass: "mx-4",
    attrs: {
      "thg": _vm.latestQuickCheckoutOption
    }
  })], 1)], 1)], 1) : _c('v-sheet', {
    staticClass: "mx-4 mb-6 mt-4 border-light-green",
    attrs: {
      "rounded": "",
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "grow"
  }, [_c('div', {
    staticClass: "ma-4"
  }, [_c('div', {
    staticClass: "font-weight-black"
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "success"
    }
  }, [_vm._v("mdi-autorenew")]), _vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicleRenewalOptionsSheet.activateAutoRenewal")) + " ")], 1), _c('div', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicleRenewalOptionsSheet.activateAutoRenewalCaption")) + " ")])])]), _c('v-col', {
    staticClass: "shrink"
  }, [_c('v-btn', {
    staticClass: "ma-4",
    attrs: {
      "color": "success",
      "loading": _vm.isLoading
    },
    on: {
      "click": function click($event) {
        return _vm.setAutoRenewal(true);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMyVehicleRenewalOptionsSheet.activateAutoRenewalButton")) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }