import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IThgVehicle, ThgVehicle } from "@/models/thg-vehicle.entity";

export const ThgVehicleDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IThgVehicle> {
  protected mapEntity(entity: IThgVehicle): IThgVehicle {
    return new ThgVehicle(entity);
  }

  getIdentifier(entity: IThgVehicle): string {
    return entity.id;
  }

  mapsConfig = {};
})();
