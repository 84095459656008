



































































































































import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import { beforeCurrentDateRule, dateRule } from "@/lib/rules/dateRule";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { numberHigherRule } from "@/lib/rules/numberHigherRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { simpleDate } from "@/lib/utility/date-helper";
import { CreateMeterReading } from "@/store/models/thg/meter-reading";
import { ChargingStationModule } from "@/store/modules/charging-station.store";
import { MeterReadingModule } from "@/store/modules/meter-reading.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ThgCreateThgMeterReadingDtoGen } from "@/services/thg/v1/data-contracts";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: { FileUploadMulti }
})
export default class ThgMeterReadingForm extends Vue {
  @Prop()
  private chargingStationId!: string;

  @Prop()
  value!: ThgCreateThgMeterReadingDtoGen;

  meterReading = new CreateMeterReading(this.value);

  @Prop({ default: false })
  isSaving!: boolean;

  dateRange: string[] = [];
  isTermsAccepted = false;

  isLoading = false;
  valid = false;

  get getMeterReadingProof() {
    return MeterReadingModule.meterReadingProof;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get numberRule() {
    return [isNumberRule(), requiredRule()];
  }

  get dateRule() {
    return [dateRule(), beforeCurrentDateRule()];
  }

  get currentYear() {
    return new Date(new Date().setFullYear(new Date().getFullYear(), 0, 1)).toISOString().substr(0, 10);
  }

  get minDate() {
    // TODO: Get minimum allowed Year from Backend

    return "2025-01-01";
  }

  get isDateRangeResetDisabled() {
    return this.dateRange.length === 0;
  }

  get isInvalidDateRange() {
    if (this.dateRange.length > 1 && this.isSameYear) {
      return true;
    }
    return false;
  }

  get isSameYear() {
    if (this.dateRange.length === 2) {
      if (new Date(this.dateRange[0]).getFullYear() !== new Date(this.dateRange[1]).getFullYear()) {
        return false;
      }
    }
    return true;
  }

  get chargingStationName() {
    return ChargingStationModule.activeChargingStation?.name || "";
  }

  get amountRule() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(numberHigherRule(this.meterReadingMinAmount));

    return rules;
  }

  /**
   * Return min amount of a meter reading
   */
  get meterReadingMinAmount(): number {
    return MeterReadingModule.minAmountInKwh;
  }

  save() {
    this.meterReading.meterReading.chargingStationId = this.chargingStationId;
    this.meterReading.meterReading.startDate = this.dateFrom;
    this.meterReading.meterReading.endDate = this.dateTo;
    this.meterReading.meterReading.proof = MeterReadingModule.meterReadingProof.map(x => x.uploadId);
    this.$emit("save", this.meterReading);
  }

  resetForm() {
    this.resetDate();
    this.meterReading = new CreateMeterReading();
  }

  resetDate() {
    this.selectedItem = 0;
    this.dateRange = [];
  }

  async getChargingstation() {
    this.isLoading = true;
    try {
      await ChargingStationModule.getChargingStation(this.chargingStationId);
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  get today() {
    return new Date().toISOString().substr(0, 10);
  }

  /**
   * Returns the first of january for the previous year
   * @example 2022-01-01
   */
  get firstDayOfLastYear() {
    const current = new Date().getFullYear();
    return new Date(new Date().setFullYear(current - 1, 0, 1)).toISOString().substr(0, 10);
  }

  /**
   * Returns the last day of december for the previous year
   * @example 2022-12-31
   */
  get lastDayOfLastYear() {
    const current = new Date().getFullYear();
    return new Date(new Date().setFullYear(current - 1, 11, 31)).toISOString().substr(0, 10);
  }

  get temporaryDateFrom(): string {
    if (this.dateRange.length) {
      const dates: number[] = [];
      for (const date of this.dateRange) {
        dates.push(new Date(date).getTime());
      }
      const tempo = new Date(new Date(Math.min.apply(null, dates)).setUTCHours(0, 0, 0, 0)).toISOString();
      return simpleDate(tempo);
    } else {
      return this.$t("components.thg.ThgMeterReadingForm.startDate").toString();
    }
  }

  get temporaryDateTo() {
    if (this.dateRange.length > 1) {
      const dates: number[] = [];
      for (const date of this.dateRange) {
        dates.push(new Date(date).getTime());
      }
      const tempo = new Date(new Date(Math.max.apply(null, dates)).setUTCHours(0, 0, 0, 0)).toISOString();
      return simpleDate(tempo);
    } else {
      return this.$t("components.thg.ThgMeterReadingForm.endDate").toString();
    }
  }

  get dateFrom() {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    return new Date(new Date(Math.min.apply(null, dates))).toISOString().substr(0, 10);
  }

  get dateTo() {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    return new Date(new Date(Math.max.apply(null, dates))).toISOString().substr(0, 10);
  }

  selectedItem = 0;

  items = [
    {
      id: 0,
      text: "components.thg.ThgMeterReadingForm.defaultDateRange",
      from: "",
      to: "",
      isActive: true
    },
    {
      id: 1,
      text: "components.thg.ThgMeterReadingForm.currentYearDateRange",
      from: this.today,
      to: this.currentYear,
      isActive: true
    },
    {
      id: 2,
      text: "components.thg.ThgMeterReadingForm.lastYearDateRange",
      from: this.firstDayOfLastYear,
      to: this.lastDayOfLastYear,
      isActive: false
    }
  ];

  get activeItems() {
    return this.items.filter(i => i.isActive);
  }

  usedPredefinedDateRange(item: any) {
    this.selectedItem = item.id;

    if (this.selectedItem === 0) {
      this.resetDate();
    }
    if (item.from) {
      this.dateRange = [item.to, item.from];
    }
  }
}
