import { Filter, IsFilterable } from "@/lib/filterable";
import { ThgThgVehicleViewModelGen } from "@/services/thg/v1/data-contracts";
import { ThgMyVehicleModule } from "@/store/modules/thg-vehicle-me.store";
import { ThgVehicle } from "./thg-vehicle.entity";

@IsFilterable
class ThgMyVehicleBase extends ThgVehicle {
  constructor(data?: Partial<ThgMyVehicleBase | ThgThgVehicleViewModelGen>) {
    super(data);
  }

  /**
   *
   */
  async fetch() {
    try {
      this.isLoading = true;
      this.map(await ThgMyVehicleModule.getOne(this.id));
    } finally {
      this.isLoading = false;
    }
  }

  /**
   *
   */
  async update() {
    try {
      this.isLoading = true;
      this.map(
        await ThgMyVehicleModule.updateOne({
          id: this.id,
          dto: {
            isAutoRenewal: this.isAutoRenewal,
            decommissioningDate: this.decommissioningDate?.toString() || ""
          }
        })
      );
    } finally {
      this.isLoading = false;
    }
  }
}

type IThgMyVehicle = ThgMyVehicleBase;
const ThgMyVehicle = Filter.createForClass(ThgMyVehicleBase);

export { IThgMyVehicle, ThgMyVehicle };
