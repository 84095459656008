

























import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";
import ThgProductsPriceCard from "@/views/thg/ThgProductsPriceCard.vue";
import { ActivePrice } from "@/store/models/thg/active-price";
import ThgProductsPriceComboCard from "@/views/thg/ThgProductsPriceComboCard.vue";

@Component({
  components: { ThgProductsPriceCard, ThgProductsPriceComboCard }
})
export default class ThgDialogYearSelection extends Vue {
  @Prop()
  possibleYears!: number[];

  @Prop()
  numberplate!: string;

  @Prop({ default: true })
  showText!: boolean;

  activeCardStyle(years: number[]) {
    if (JSON.stringify(this.years) === JSON.stringify(years)) {
      return "primary";
    }
    return "";
  }

  get years() {
    return ThgCreateModule.years;
  }

  set years(years: number[]) {
    ThgCreateModule.setYears(years);
  }

  get activeProducts() {
    const price = new ActivePrice(
      PartnerActiveOfferModule.prices.filter(item => this.possibleYears.includes(item.year))
    );

    return price.getYearCombinationsByVehicleClass(ThgVehicleTypes.M1);
  }

  get selectedYears() {
    return ThgCreateModule.years;
  }

  set selectedYears(years: number[]) {
    ThgCreateModule.setYears(years);
  }
}
