




































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DropAreaMulti extends Vue {
  @Prop({ type: String, default: "" })
  dragoverText!: string;

  @Prop({ type: String, default: "" })
  text!: string;

  @Prop({ type: Number, default: 1000 })
  maxFiles!: number;

  @Prop({ type: Boolean, default: false })
  loading!: boolean;

  @Prop({ type: Number, default: 0 })
  loadingProgress!: number;

  @Prop({ type: String, default: ".webp, .jpg, .jpeg, .png" })
  accept!: string;

  @Prop()
  files!: File[];

  get filesModel() {
    return this.files;
  }

  set filesModel(v: File[]) {
    this.$emit("update:files", v);
  }

  isDragging = false;

  get fileNamePreviews() {
    const fileCount = this.filesModel.length;

    // Extract the names of the first 3 files
    const previewNames = this.filesModel.slice(0, 3).map(file => file?.name);

    // Calculate the number of remaining files
    const remainingCount = fileCount - 3;

    // If there are more than 3 files, add "and X more"
    if (remainingCount > 0) {
      return `${previewNames.join(", ")} ${this.$t("and")} ${remainingCount}  ${this.$t("more")}`;
    }

    // If there are 3 or fewer files, return the names properly formatted
    if (previewNames.length > 1) {
      return `${previewNames.slice(0, -1).join(", ")}  ${this.$t("and")} ${previewNames.slice(-1)}`;
    }

    // If there is only one file, simply return its name
    return previewNames[0] || "";
  }

  dragOver(event: DragEvent) {
    if (!this.loading && event.dataTransfer) {
      this.isDragging = true;
      event.dataTransfer.dropEffect = "copy";
    }
  }

  dragLeave() {
    if (!this.loading) {
      this.isDragging = false;
    }
  }

  async onFileDrop(event: DragEvent) {
    if (this.loading) return;
    this.isDragging = false;
    const dataTransfer = event.dataTransfer;
    if (!dataTransfer) return;

    const files = Array.from(dataTransfer.files);
    if (files.length === 0) return;

    if (files.length > this.maxFiles) {
      this.$toast.error(this.$t("components.DropAreaMulti.maxFiles", { value: this.maxFiles }));
      return;
    }

    this.handleFile(files);
  }

  triggerFileInput() {
    if (!this.loading) {
      (this.$refs.fileInput as HTMLInputElement).click();
    }
  }

  onFileSelect(event: Event) {
    if (this.loading) return;
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files = Array.from(input.files);
      this.handleFile(files);
    }
  }

  emitUploadedFile(files: File[]) {
    this.$emit("droped", files);
    this.$emit("dropped", files);
  }

  handleFile(files: File[]) {
    this.filesModel = [];
    const invalidFiles = files.filter(file => !this.isImage(file));

    if (invalidFiles.length > 0) {
      this.displayNotAnImage();
      return;
    }

    this.filesModel = files;

    this.emitUploadedFile(files);
  }

  isImage(file: File) {
    const fileExtension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
    return this.accept.includes(fileExtension);
  }

  displayNotAnImage() {
    this.$toast.error(this.$t("components.DropAreaMulti.fileTypeError") + " (" + this.accept + ")");
  }
}
