


































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThgMyVehicle } from "@/models/thg-my-vehicle.entity";
import { ThgThgMeViewModelGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import ImageDialog from "../utility/ImageDialog.vue";
import NumberplateForm from "../utility/NumberplateForm.vue";
import ThgMyVehicleDecommissionDialog from "./ThgMyVehicleDecommissionDialog.vue";
import ThgMyVehicleRegistrationDetail from "./ThgMyVehicleRegistrationDetail.vue";
import ThgMyVehicleRenewalOptionsSheet from "./ThgMyVehicleRenewalOptionsSheet.vue";
import ThgMyVehicleThgQuoteDetail from "./ThgMyVehicleThgQuoteDetail.vue";

@Component({
  components: {
    NumberplateForm,
    ImageDialog,
    ThgMyVehicleRenewalOptionsSheet,
    ThgMyVehicleRegistrationDetail,
    ThgMyVehicleThgQuoteDetail,
    ThgMyVehicleDecommissionDialog
  }
})
export default class ThgMyVehicle extends DarkModeHighlightMixin {
  @Prop()
  vehicle!: IThgMyVehicle;

  @Prop()
  thgs!: ThgThgMeViewModelGen[];

  decomissionDialog = false;

  get numberplate() {
    if (this.thgs.length) {
      return this.thgs[0]?.numberplate || "";
    }

    return "";
  }

  openDecommissioningDialog() {
    (this.$refs.decommisionDialog as ThgMyVehicleDecommissionDialog)?.open();
  }
}
